<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
    <b-form
      @submit.stop.prevent="handleSubmit(quotation)"
      class="form"
      autocomplete="off"
    >
      <div class="container container-cozy">
        <div class="row">
          <div class="col-12 text-center">
            <header class="mb-5">
              <h2 class="text-title-step">
                Descomplique o seu seguro fiança locatícia.
              </h2>
              <p>A cotação e contratação mais rápida do mercado.</p>
            </header>
          </div>
          <div v-if="alertMessage" class="col-12">
            <div class="alert-custom alert-danger-custom">
              {{ alertMessage }}
            </div>
          </div>
          <div class="col-12">
            <div class="col-12 col-md-8 offset-md-2 col mb-3 text-center">
              <label class="dbo-ui">Selecione o tipo de pessoa</label>
              <div class="list-group list-group-horizontal">
                <a
                  @click="naturalType()"
                  class="list-group-item list-group-item-action"
                  :class="{ active: naturalSelected }"
                  >Pessoa física</a
                >
                <a
                  @click="legalType()"
                  class="list-group-item list-group-item-action"
                  :class="{ active: legalSelected }"
                >
                  Pessoa jurídica
                </a>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr />
              <div class="hr-label">Preencha seus dados abaixo</div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  :name="checkNameCpfOrCpnj"
                  rules="required"
                  v-slot="validationContext"
                  vid="document"
                >
                  <label class="dbo-ui">{{ checkNameCpfOrCpnj }}</label>
                  <b-form-input
                    v-mask="checkMaskCpfOrCpnj"
                    v-model="dataForm.document"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <label class="dbo-ui">CEP do endereço atual</label>
                <validation-provider
                  name="cep"
                  rules="required"
                  v-slot="validationContext"
                  vid="zipcode"
                  v-model="dataForm.zipcode"
                >
                  <b-form-input
                    v-mask="cepMask"
                    v-model="dataForm.zipcode"
                    :state="getValidationState(validationContext)"
                    placeholder="CEP do endereço atual"
                    @change="searchCep(dataForm.zipcode)"
                  ></b-form-input>
                  <b-spinner small v-if="loadingCep"></b-spinner>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider
                  :name="checkNameOrCompany"
                  rules="required|max:191|fullname"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui">{{ checkNameOrCompany }}</label>
                  <b-form-input
                    :autofocus="focus"
                    v-model="dataForm.name"
                    :state="getValidationState(validationContext)"
                    ref="razao_social"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <template v-if="naturalSelected != definition">
                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Finalidade da Locação"
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Finalidade da Locação</label>
                    <select
                      class="form-control"
                      v-model="dataForm.purpose_lease"
                    >
                      <option
                        v-for="(finality, index) in dataFinality"
                        :key="index"
                        :value="finality"
                      >
                        {{ index }}
                      </option>
                    </select>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Ramo de Atividade"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <label class="dbo-ui">Ramo de Atividade</label>
                    <select
                      class="form-control"
                      v-model="dataForm.branch_activity"
                    >
                      <option
                        v-for="(activity, index) in dataActivity"
                        :key="index"
                        :value="activity"
                      >
                        {{ index }}
                      </option>
                    </select>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Faturamento Mensal Médio"
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Faturamento Mensal Médio</label>
                    <money
                      v-bind="money"
                      v-model="dataForm.average_monthly_billing"
                      :state="getValidationState(validationContext)"
                      @keypress.native="getValidationLess($event)"
                      class="form-control"
                    />
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Opção Tributária"
                    rules="required"
                  >
                    <label class="dbo-ui">Opção Tributária</label>
                    <select class="form-control" v-model="dataForm.tax_option">
                      <option
                        v-for="(tax, key) in taxTypes"
                        :key="key"
                        :value="tax"
                      >
                        {{
                          getParamEnumObjByEnumKey(
                            translateTaxTypes,
                            key,
                            "title"
                          )
                        }}
                      </option>
                    </select>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Telefone para Contato"
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Telefone para Contato</label>
                    <b-form-input
                      :autofocus="focus"
                      v-model="dataForm.contact"
                      :state="getValidationState(validationContext)"
                      placeholder="Telefone para contato"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Telefone da Empresa"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <label class="dbo-ui">Telefone da Empresa</label>
                    <b-form-input
                      :autofocus="focus"
                      v-model="dataForm.company"
                      :state="getValidationState(validationContext)"
                      placeholder="Telefone da Empresa"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </template>
              <template v-else>
                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Estado civil"
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Estado civil</label>
                    <select
                      class="form-control"
                      v-model="dataForm.marital_status"
                    >
                      <option
                        v-for="(
                          finality, index
                        ) in dataMaritalStatusTypes"
                        :key="index"
                        :value="finality"
                      >
                        {{ index }}
                      </option>
                    </select>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Data de nascimento"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <label class="dbo-ui">Data de nascimento</label>
                    <b-form-input
                      v-model="dataForm.birthdate"
                      type="date"
                      :state="getValidationState(validationContext)"
                      placeholder=""
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Residirá o imóvel"
                    rules="required"
                    
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Residirá o imóvel?</label>
                    <b-form-radio
                      v-model="dataForm.resided_immobile"
                      name="resided_immobile"
                      value="1"
                      class="radio-button-locaticia"
                      :state="getValidationState(validationContext)"
                      >Sim</b-form-radio
                    >
                    <b-form-radio
                      v-model="dataForm.resided_immobile"
                      name="resided_immobile"
                      value="0"
                      
                      :state="getValidationState(validationContext)"
                      >Não</b-form-radio
                    >
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Responderá financeiramente"
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Responderá financeiramente?</label>
                    <b-form-radio
                      v-model="dataForm.answer_financially"
                      name="answer_financially"
                      value="1"
                      :state="getValidationState(validationContext)"
                      >Sim</b-form-radio
                    >
                    <b-form-radio
                      v-model="dataForm.answer_financially"
                      name="answer_financially"
                      value="0"
                      :state="getValidationState(validationContext)"
                      >Não</b-form-radio
                    >
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </template>
              <div
                class="col-12"
                v-if="dataForm.purpose_lease == 'locacao_moradia'"
              >
                <validation-provider
                  name="Nome da Real Ocupante"
                  rules="required"
                  v-slot="validationContext"
                  vid="document"
                >
                  <label class="dbo-ui">Nome da Real Ocupante</label>
                  <b-form-input
                    v-model="dataForm.occupant_name"
                    :state="getValidationState(validationContext)"
                    placeholder="Nome da Real Ocupante"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div
                class="col-12 col-lg-6 mb-3"
                v-if="dataForm.purpose_lease == 'locacao_moradia'"
              >
                <validation-provider
                  name="CPF"
                  rules="required"
                  v-slot="validationContext"
                  vid="document"
                >
                  <label class="dbo-ui">CPF</label>
                  <b-form-input
                    v-mask="cpfMask"
                    v-model="dataForm.occupant_document"
                    :state="getValidationState(validationContext)"
                    placeholder="CPF"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <template v-if="dataForm.answer_financially == definition">
                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Vínculo empregatício"
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Vínculo empregatício</label>
                    <select
                      class="form-control"
                      v-model="dataForm.employment_relationship"
                    >
                      <option
                        v-for="(
                          finality, index
                        ) in dataEmploymentRelationshipTypes"
                        :key="index"
                        :value="finality"
                      >
                        {{ index }}
                      </option>
                    </select>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Profissão"
                    rules="max:100"
                    v-slot="validationContext"
                  >
                    <label class="dbo-ui">Profissão</label>
                    <b-form-input
                      :autofocus="focus"
                      v-model="dataForm.profession"
                      :state="getValidationState(validationContext)"
                      placeholder="Profissão"
                      ref="profissao"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Renda principal "
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Renda principal</label>
                    <money
                      v-bind="money"
                      v-model="dataForm.main_income"
                      :state="getValidationState(validationContext)"
                      @keypress.native="getValidationLess($event)"
                      class="form-control"
                    />
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Outras rendas"
                    rules="required"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Outras rendas</label>
                    <money
                      v-bind="money"
                      v-model="dataForm.other_income"
                      :state="getValidationState(validationContext)"
                      @keypress.native="getValidationLess($event)"
                      class="form-control"
                    />
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </template>

              <div class="col-12">
                <validation-provider
                  name="Observações"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui">Observações</label>
                  <b-form-textarea
                    v-model="dataForm.comments"
                    placeholder="Observações..."
                    rows="6"
                  ></b-form-textarea>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 pt-4">
                <a class="btn btn-outline-primary" @click="addNewPartner">
                  <i class="fa fa-plus mr-2"></i> {{checkPartnerOrSuitor}}
                </a>
              </div>

              <div
                class="col-12 pt-4"
                v-for="(partner, index) in dataForm.partners"
                :key="index"
              >
                <b-card class="shadow">
                  <template #header>
                    <div class="row">
                      <div class="col-6">
                        <h3>Sócio {{ index + 1 }}</h3>
                      </div>
                      <div class="col-6 text-right">
                        <b-button
                          size="sm"
                          variant="light"
                          class="btn-outline-primary"
                          @click="dataForm.partners.splice(index, 1)"
                        >
                          <i class="fa fa-times mr-2"></i> Excluir
                        </b-button>
                      </div>
                    </div>
                  </template>
                  <b-card-text>
                    <div id="partner">
                      <validation-provider
                        name="Observações"
                        v-slot="validationContext"
                      >
                        <div
                          class="
                            list-group list-group-horizontal
                            mb-3
                            text-center
                          "
                          id="list-tab"
                          role="tablist"
                        >
                          <span
                            @click="changeToNaturalType(index)"
                            :class="{ active: partner.naturalSelected }"
                            class="
                              pointer
                              list-group-item list-group-item-action
                              radio-btn-outline
                            "
                            id="list-home-list"
                            >Pessoa física</span
                          >
                          <span
                            @click="changeToLegalType(index)"
                            :class="{ active: partner.legalSelected }"
                            class="
                              pointer
                              list-group-item list-group-item-action
                              radio-btn-outline
                            "
                            id="list-profile-list"
                            >Pessoa jurídica</span
                          >
                        </div>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div v-if="partner.type">
                      <div class="row">

                        <div class="col-12 mt-3">
                          <b-form-input
                            v-model="partner.id"
                            hidden
                          ></b-form-input>
                          <validation-provider
                            :name="
                              partner.naturalSelected
                                ? nameNaturalLabel
                                : nameLegalLabel
                            "
                            rules="required"
                            v-slot="validationContext"
                            vid="document"
                          >
                            <label class="dbo-ui">{{
                              partner.naturalSelected
                                ? nameNaturalLabel
                                : nameLegalLabel
                            }}</label>
                            <b-form-input
                              v-model="partner.name"
                              :state="getValidationState(validationContext)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </validation-provider>
                        </div>

                        <div class="col-7 mt-3">
                          <validation-provider
                            :name="
                              partner.naturalSelected
                                ? documentNaturalLabel
                                : documentLegalLabel
                            "
                            rules="required"
                            v-slot="validationContext"
                            vid="document"
                          >
                            <label class="dbo-ui">{{
                              partner.naturalSelected
                                ? documentNaturalLabel
                                : documentLegalLabel
                            }}</label>
                            <b-form-input
                              v-mask="partnerMask[index]"
                              v-model="partner.document"
                              :state="getValidationState(validationContext)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </validation-provider>
                        </div>

                        <template v-if="dataForm.partners[index].type == type">
                          
                          <div class="col-6 mt-3">
                            <validation-provider
                              name="Residirá o imóvel"
                              rules="required"
                              
                              v-slot="validationContext"
                              vid="document"
                            >
                              <label class="dbo-ui">Residirá o imóvel?</label>
                              <b-form-radio
                                v-model="partner.resided_immobile"
                                :name="'partner_resided_immobile'+ (index + 1)"
                                value="1"
                                class="radio-button-locaticia"
                                :state="getValidationState(validationContext)"
                                >Sim</b-form-radio
                              >
                              <b-form-radio
                                v-model="partner.resided_immobile"
                                :name="'partner_resided_immobile'+ (index + 1)"
                                value="0"
                                
                                :state="getValidationState(validationContext)"
                                >Não</b-form-radio
                              >
                              <b-form-invalid-feedback>{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                            </validation-provider>
                          </div>

                          <div class="col-6 mt-3">
                            <validation-provider
                              name="Responderá financeiramente"
                              rules="required"
                              v-slot="validationContext"
                              vid="document"
                            >
                              <label class="dbo-ui">Responderá financeiramente?</label>
                              <b-form-radio
                                v-model="partner.answer_financially"
                                :name="'partner_answer_financially_'+ (index + 1)"
                                value="1"
                                :state="getValidationState(validationContext)"
                                >Sim</b-form-radio
                              >
                              <b-form-radio
                                v-model="partner.answer_financially"
                                :name="'partner_answer_financially_'+ (index + 1)"
                                value="0"
                                :state="getValidationState(validationContext)"
                                >Não</b-form-radio
                              >
                              <b-form-invalid-feedback>{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                            </validation-provider>
                          </div>

                          <template v-if="partner.answer_financially == definition">
                            <div class="col-12 col-lg-6 mb-3">
                              <validation-provider
                                name="Vínculo empregatício"
                                rules="required"
                                v-slot="validationContext"
                                vid="document"
                              >
                                <label class="dbo-ui">Vínculo empregatício</label>
                                <select
                                  class="form-control"
                                  v-model="partner.employment_relationship"
                                >
                                  <option
                                    v-for="(
                                      finality, index
                                    ) in dataEmploymentRelationshipTypes"
                                    :key="index"
                                    :value="finality"
                                  >
                                    {{ index }}
                                  </option>
                                </select>
                                <b-form-invalid-feedback>{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback>
                              </validation-provider>
                            </div>

                            <div class="col-12 col-lg-6 mb-3">
                              <validation-provider
                                name="Profissão"
                                rules="max:100"
                                v-slot="validationContext"
                              >
                                <label class="dbo-ui">Profissão</label>
                                <b-form-input
                                  :autofocus="focus"
                                  v-model="partner.profession"
                                  :state="getValidationState(validationContext)"
                                  placeholder="Profissão"
                                  ref="profissao"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback>
                              </validation-provider>
                            </div>

                            <div class="col-12 col-lg-6 mb-3">
                              <validation-provider
                                name="Renda principal "
                                rules="required"
                                v-slot="validationContext"
                                vid="document"
                              >
                                <label class="dbo-ui">Renda principal</label>
                                <money
                                  v-bind="money"
                                  v-model="partner.main_income"
                                  :state="getValidationState(validationContext)"
                                  @keypress.native="getValidationLess($event)"
                                  class="form-control"
                                />
                                <b-form-invalid-feedback>{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback>
                              </validation-provider>
                            </div>

                            <div class="col-12 col-lg-6 mb-3">
                              <validation-provider
                                name="Outras rendas"
                                rules="required"
                                v-slot="validationContext"
                                vid="document"
                              >
                                <label class="dbo-ui">Outras rendas</label>
                                <money
                                  v-bind="money"
                                  v-model="partner.other_income"
                                  :state="getValidationState(validationContext)"
                                  @keypress.native="getValidationLess($event)"
                                  class="form-control"
                                />
                                <b-form-invalid-feedback>{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback>
                              </validation-provider>
                            </div>
                          </template>

                        </template>

                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </div>
              <div class="col-12 col-lg-6 mb-3"></div>

              <div class="col-12 col-lg-12 mt-5">
                <button
                  :disabled="invalid"
                  class="button btn btn-primary btn-pretty icon-right"
                >
                  Informar dados <i class="fa fa-angle-right ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
    <div
      v-if="loading"
      class="overlay d-flex align-items-center justify-content-center"
    >
      <b-spinner></b-spinner>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import rentalGuaranteeService from "@/services/rentalGuarantee";
import { extend } from "vee-validate";
import commonsService from "@/services/commons";
import uiHelperMixin from "@/mixins/uiHelper";
import searchCep from "@/mixins/typesAnalytics/searchCep";
import translateDataActivityTypes from "@/enums/translate/dataActivityTypes";
import translateFinalityTypes from "@/enums/translate/finalityTypes";
import translateEmploymentRelationshipTypes from "@/enums/translate/relationshipTypes";
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import translateMaritalStatusTypes from "@/enums/translate/maritalStatusTypes";
import translateTaxTypes from "@/enums/translate/taxTypes";
import taxTypes from "@/enums/taxTypes";
import userTypes from "@/enums/userTypes";
import commomsHelperMixin from '@/mixins/commomsHelper';

extend("fullname", (value) => {
  const nameParts = value.split(" ");
  return nameParts.length > 1 || "Você deve informar seu nome completo";
});

export default {
  name: "GeneralData",
  mixins: [uiHelperMixin, searchCep, commomsHelperMixin],
  props: {
    renterQuotationId: {
      type: String,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    mask,
  },
  data: () => ({
    dataForm: {
      partners: [],
      suitor_guarantees: [],
    },
    loading: false,
    focus: false,
    validate: false,
    loadingCep: false,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    dataFinality: translateFinalityTypes,
    dataActivity: translateDataActivityTypes,
    dataEmploymentRelationshipTypes: translateEmploymentRelationshipTypes,
    insuranceCategoryTypes: insuranceCategoryTypes,
    taxTypes: taxTypes,
    translateTaxTypes: translateTaxTypes,
    dataMaritalStatusTypes: translateMaritalStatusTypes,
    dataTypePartner: [
      { text: "Pessoa Física", value: "pessoa_fisica" },
      { text: "Pessoa Jurídica", value: "pessoa_juridica" },
    ],
    type: userTypes.NATURAL,
    definition: 1,
    partnerMask: []
  }),
  computed: {
    checkPartnerOrSuitor(){
      return this.type == userTypes.NATURAL ? "Incluir Pretendente" : "Incluir Sócio";
    },
    checkNameCpfOrCpnj() {
      return this.type == userTypes.NATURAL ? "CPF" : "CNPJ";
    },
    checkMaskCpfOrCpnj() {
      return this.type == userTypes.NATURAL
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    checkNameOrCompany() {
      return this.type == userTypes.NATURAL ? "Nome" : "Razão Social";
    },
    naturalSelected() {
      return this.type == userTypes.NATURAL;
    },
    legalSelected() {
      return this.type == userTypes.LEGAL;
    },
    document() {
      return this.dataForm.document;
    },
    documentMask() {
      return "##.###.###/####-##";
    },
    cpfMask() {
      return "###.###.###-##";
    },
    documentNaturalLabel() {
      return "CPF";
    },
    documentLegalLabel() {
      return "CNPJ";
    },
    nameNaturalLabel() {
      return "nome completo";
    },
    nameLegalLabel() {
      return "razão social";
    },
    cepMask() {
      return "#####-###";
    },
  },
  created() {
    if (this.renterQuotationId) {
      this.getQuotation(this.renterQuotationId);
    }
    this.setInsuranceCategory();
    this.scroll();
  },
  methods: {
    getValidationLess(event) {
      return event.key === "-" ? event.preventDefault() : null;
    },
    async getQuotation(uid) {
      await rentalGuaranteeService
        .getByUID(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.fillFields(response.data, true);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a cotação.");
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    storeQuotation() {
      return rentalGuaranteeService.store(this.dataForm);
    },
    updateQuotation() {
      return rentalGuaranteeService.update(
        this.renterQuotationId,
        this.dataForm
      );
    },
    quotation() {
      this.resetAlert();
      this.loading = true;
      let savePromise = this.renterQuotationId
        ? this.updateQuotation()
        : this.storeQuotation();
      savePromise
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          uid = this.renterQuotationId;
          if (!uid) {
            var uid = response.data;
          }
          this.$router.push({
            name: "data-property-desired",
            params: { renterQuotationId: uid },
          });
        })
        .catch(() => {
          this.showError("Não foi possível executar a cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getParamEnumObjByEnumKey(myEnum, enumValue, param) {
      let key = Object.keys(myEnum).filter((x) => x == enumValue);
      return key.length > 0 ? myEnum[key[0]][param] : null;
    },
    fillFields(element, firstAccess = false) {
      if (firstAccess) {
        this.validate = true;
        this.$set(this.dataForm, "document", element.document);
      } else {
        this.validate = false;
      }
      this.dataForm.zipcode = element.suitor_guarantees.zipcode;
      this.dataForm.name = element.name;
      this.dataForm.purpose_lease = element.suitor_guarantees.purpose_lease;
      this.dataForm.branch_activity = element.suitor_guarantees.branch_activity;
      this.dataForm.average_monthly_billing =
        element.suitor_guarantees.average_monthly_billing;
      this.dataForm.tax_option = element.suitor_guarantees.tax_option;
      this.dataForm.contact = element.suitor_guarantees.contact_phone;
      this.dataForm.company = element.suitor_guarantees.company_phone;
      this.dataForm.occupant_name = element.suitor_guarantees.occupant_name;
      this.dataForm.occupant_document =
        element.suitor_guarantees.occupant_document;
      this.dataForm.comments = element.suitor_guarantees.comments;
      this.dataForm.partners = element.partner;
      this.setFocus();
    },
    setFocus() {
      this.focus = true;
      this.focus = false;
    },
    findCnpj(cnpj) {
      this.resetAlert();
      this.resetFields();
      this.loading = true;
      commonsService
        .findCnpj(this.traitCnpj(cnpj))
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.dataForm.name = response.data.name;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as informações do cnpj.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetFields() {
      this.dataForm = {
        document: this.dataForm.document,
        partners: this.dataForm.partners,
        suitor_guarantees: this.dataForm.suitor_guarantees,
      };
      this.setFocus();
    },
    addNewPartner: function () {
      this.dataForm.partners.push({
        type: "",
      });
    },
    changeToNaturalType(index) {
      this.dataForm.partners[index].type = userTypes.NATURAL;
      this.dataForm.partners[index].naturalSelected = true;
      this.dataForm.partners[index].legalSelected = false;
      this.partnerMask[index] = "###.###.###-##";
    },
    changeToLegalType(index) {
      this.dataForm.partners[index].type = userTypes.LEGAL;
      this.dataForm.partners[index].naturalSelected = false;
      this.dataForm.partners[index].legalSelected = true;
      this.partnerMask[index] = "##.###.###/####-##";
    },
    naturalType() {
      this.type = userTypes.NATURAL;
    },
    legalType() {
      this.type = userTypes.LEGAL;
    },
    setInsuranceCategory() {
      this.dataForm.insurance_category_id = this.insuranceCategoryTypes.RENTAL_GUARANTEE;
    }
  },
  watch: {
    document(value) {
      if (!this.validate) {
        if (value.length == 18) {
          this.findCnpj(value);
        }
        return;
      }
      this.validate = false;
    },
  },
};
</script>

<style></style>
