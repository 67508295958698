<template>

  <div :class="isJudicial()+' col-12 mb-3'">
    <div
      class="card card-button h-100"
      :class="classOptions"
      :style="styleOptions"
      @click="onClick(insuranceCategory)"
      v-b-tooltip.hover.v-primary="tooltipOptions" :title="disabledMessager"
    >
      <div class="card-body shadow">
        <span class="fa-stack-custom fa-3x mb-3">
          <i class="fa fa-circle fa-stack-2x"></i>
          <i class="fa fa-stack-1x fa-inverse" :class="insuranceCategory.class"></i>
        </span>
        <p class="no-break">{{ insuranceCategory.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsuranceCategoryCard",
  props: {
    insuranceCategory: {
      type: Object,
      required: true
    },
    selectedInsuranceCategory: {
      type: Object,
    },
    comingSoon: {
      type: Boolean,
      default: false
    },
    comingSoonMessage: {
      type: String,
      default: 'Em breve essa categoria estará disponível para você'
    },
    disabled: {
      default: false
    },
  },
  computed: {
    classOptions() {
      return {
        'pointer': !this.comingSoon
      };
    },
    styleOptions() {
      let style = 'opacity: 0.5';
      if (!this.comingSoon) {
        style = 'opacity: 1';
        if (this.disabled) {
          style = 'opacity: 0.5 !important; cursor:auto !important;'
        }
      }
      return style;
    },
    tooltipOptions() {
      return {
        disabled: !this.comingSoon 
      };
    },
    disabledMessager()
    {
      return this.disabled ? this.comingSoonMessage : ''
    }
  },
  methods: {
    onClick(insuranceCategory) {
      if (this.comingSoon || this.disabled) {
        return;
      }
      this.$emit('click', insuranceCategory);
    },
    isJudicial() {
      return 'col-lg-4'
    }
  }
}
</script>

<style>

</style>
