<template>
  <div class="container container-cozy">
    <div class="row">
      <div class="col-12 col-lg-12">
        <header class="mb-5 text-center">
          <h2>
            <i class="fa fa-check-circle text-primary"></i> Pronto!<br />
            <small>Recebemos com sucesso suas informações.</small>
          </h2>
        </header>

       <div v-if="alertMessage" class="col-12 w-100 px-0">
          <div v-if="this.guarantee_start < this.getCurrentDate()" @click="updateGuaranteeDate" class="alert-custom alert-danger-custom">
            <a class="text-white text-decoration-none"> {{ alertMessage }} </a>
          </div>
          <div v-else class="alert-custom alert-danger-custom">
            <a class="text-white text-decoration-none"> {{ alertMessage }} </a>
          </div>
      </div>

        <div class="row">
          <div v-if="!loading" class="d-table mx-auto text-center fs-20 col-10">
            <template v-if="showProposal">
              <div class="card shadow border card-icon card-icon-top border-primary mb-4" v-if="checkBerkley">
                <i class="fa fa-exclamation text-primary"></i>
                <div class="card-body text-center">
                  <p class="font-18"><strong class="text-primary">Atenção!</strong></p>
                  <p class="mx-auto mb-0 font-16" style="max-width: 41ch;">Para visualizar a minuta abaixo e qualquer outro documento desta cotação, por favor informe os <strong>5 (cinco) primeiros dígitos do CNPJ do tomador</strong> ao abrir o documento.</p>
                </div>
              </div>
              <p v-else>
                Segue abaixo a minuta da sua cotação:
              </p>
                <object :data="quotation.guarantee.proposal_url" style="width: 100%" height="500"></object>
            </template>
            <template v-else-if="showPolicyAndBilling">
              <p>
                Segue abaixo o documento de apólice e boleto:
              </p>
                <object :data="policy.billing_file" style="width: 100%" height="500"></object>
                <object :data="policy.document_file" style="width: 100%" height="500"></object>
            </template>
            <template v-else-if="asyncPolicyIssued">
              <p>
                Tudo certo! A sua apólice está sendo emitida.<br />
                Em breve você receberá um e-mail contendo o documento da apólice e o boleto para pagamento.
              </p>
            </template>
            <template v-else-if="policyRequested">
              <p>
                Sua solicitação de emissão de apólice foi enviada com sucesso.<br />
                Após a aprovação da emissão, você receberá um e-mail contendo todas as informações desta demanda.
              </p>
            </template>
            <template v-else>
              <p>
                Em breve você receberá a cotação no e-mail e/ou WhatsApp
                indicados.<br />
                Para casos urgentes, por favor, nos sinalize através dos contatos
                abaixo.
              </p>

              <p>
                A sua cotação passará por uma análise e entraremos em contato por
                WhatsApp,<br />
                telefone e/ou e-mail para falarmos a respeito dela.
              </p>
            </template>
          </div>
        </div>

        <div class="row d-table mx-auto text-center">
          <div class="col-12 col-lg-12 mt-5">
            <button
              v-if="showProposal && $can('policy-issue')"
              :disabled="isRetroactive"
              @click="showModalTerms"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Emitir Apólice
            </button>
            <button
              v-if="showProposal && !$can('policy-issue')"
              @click="requestPolicy"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Solicitar emissão de Apólice
            </button>
          </div>

          <hr />

          <p>
            <strong
              >Aqui estão os dados do especialista em seguro garantia, caso
              precise de urgência:</strong
            >
          </p>

          <div class="card shadow d-table mx-auto border">
            <div class="card-body">
              <p class="mb-0">
                <i class="fa fa-fw fa-envelope"></i>
                <a :href="'mailto:' + contact_info.APP_FINN_EMAIL">
                  {{ contact_info.APP_FINN_EMAIL }}
                </a><br />
                <i class="fab fa-fw fa-whatsapp"></i>
                <a :href="'https://api.whatsapp.com/send?l=pt&amp;phone=' + ('55 ' + contact_info.APP_FINN_TELEPHONE).replace(/\D/g,'')" target="_blank">
                  {{ contact_info.APP_FINN_TELEPHONE }}
                </a><br />
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <b-modal v-model="show" id="modal-cotacao-status" size="lg" :hide-cancel="true" v-if="term">
      <template #modal-title>
        <div>
          <h5 class="modal-title" id="modal-cotacao-status-label">{{term.title}}</h5>
        </div>
      </template>
      <div>
        <p class="text-justify">
            {{term.description}}
        </p>
        <div
          class="mb-4">
            <b-form-checkbox
              name="checkbox-1"
              v-model="acceptTerms"
            >
              <b>Declaro que li e estou de acordo com os termos de uso.</b>
          </b-form-checkbox>
        </div>
      </div>
      <template #modal-footer>
        <div>
          <button type="button" @click="show = false" class="btn btn-primary text-uppercase">Cancelar</button>
          <button
            type="button"
            v-if="$can('policy-issue')"
            :disabled="!acceptTerms"
            @click="generatePolicy(term.id)"
            class="btn btn-primary ml-2 text-uppercase">
            Emitir Apólice
          </button>
        </div>
      </template>
    </b-modal>

    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center"><b-spinner></b-spinner></div>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import commonsService from "@/services/commons";
import termsTypes from "@/enums/termsTypes";
import enumContactInfo from "@/enums/contactInfo";
import dateFormatter from '@/mixins/dateFormatter';
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import commomsHelper from '@/mixins/commomsHelper';
import translateInsurersTypes from "@/enums/translate/insurersTypes";

export default {
  name: "FinalFeedback",
    mixins: [
     commomsHelper, dateFormatter
    ],
  props: {
    quotationId: {
      type: String
    },
  },
  data: () => ({
    quotation: null,
    show: false,
    acceptTerms: false,
    policy: null,
    loading: false,
    asyncPolicyIssued: false,
    policyRequested: false,
    termsTypes: termsTypes,
    term: null,
    contact_info: enumContactInfo,
    isRetroactive: null,
    insuranceCategoryTypes: insuranceCategoryTypes,
    guarantee_start: null,
    quotationCategoryId: null,
    translateInsurersTypes: translateInsurersTypes,
  }),
  created() {
    this.getQuotation();
  },
  computed: {
    showProposal() {
      return !this.showPolicyAndBilling &&
        !this.loading &&
        !!this.quotation &&
        !!this.quotation.guarantee &&
        !!this.quotation.guarantee.proposal_url &&
        !this.asyncPolicyIssued &&
        !this.policyRequested;
    },
    showPolicyAndBilling() {
      return !this.loading &&
        !!this.policy &&
        !!this.policy.billing_file &&
        !!this.policy.document_file &&
        !this.asyncPolicyIssued &&
        !this.policyRequested;
    },
    checkBerkley() {
      return !!this.quotation &&
        !!this.quotation.guarantee &&
        !!this.quotation.guarantee.insurer_selected &&
        this.quotation.guarantee.insurer_selected == this.translateInsurersTypes['Berkley'] &&
        !this.asyncPolicyIssued &&
        !this.policyRequested;
    }
  },
  methods: {
    getTerm(){
      commonsService
        .getTerm(termsTypes.POLICY_ACCEPTANCE_TERM)
        .then(response => {
          this.term = response.data
          if(this.$route.query.showTerms){
            this.showModalTerms()
          }
        })
        .catch(() => {
          this.showError("Não foi possível recuperar o termo.");
        })
    },
    getQuotation() {
      this.loading = true;
      quotationService
        .getByUID(this.quotationId)
        .then(response => {
          this.quotation = response.data
          this.quotationCategoryId = response.data.insurance_category_id
          this.findGuarantee(response.data.id);
          if (this.$can('policy-issue')) {
            this.getTerm();
          }
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalTerms(){
      if(this.term){
        this.show = true
        this.acceptTerms = false
        return
      }
      this.showError("Não foi possível recuperar o termo de aceite de apólice.");
    },
    generatePolicy(termId) {
      this.loading = true;
      this.show = false;
      quotationService
        .generatePolicy(this.quotationId, termId)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          if (response.status == 202) {
            this.asyncPolicyIssued = true;
            return;
          }

          this.policy = response.data
        })
        .catch(() => {
          this.showError("Não foi possível emitir a apólice.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    requestPolicy() {
      this.loading = true;
      this.show = false;
      quotationService
        .requestGeneratePolicy(this.quotationId)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          this.policyRequested = true;
        })
        .catch(() => {
          this.showError("Não foi possível solicitar a emissão.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    findGuarantee(uid) {
      quotationService
        .getGuaranteeByUID(uid)
        .then((response) => {
          this.guarantee_start = response.data.guarantee_start;
          if(response.data.guarantee_start < this.getCurrentDate() && this.isCategoryCurrentDate()){
           this.isRetroactive = true;
           this.showError("A data de início da vigência não pode ser menor que a data atual. Clique aqui para reiniciar a cotação com a data atual.");
          }
        })
    },
    updateGuaranteeDate(){
       this.$router.push({
          name: "garantee-data",
          params: { quotationId: this.getIdFromUrl() },
        });
    },    
    isCategoryCurrentDate() {
      return [
        this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.PARTICIPATE_IN_A_BID_OR_COMPETITION,
      ].some(value => value === this.quotationCategoryId);
    },
  },
};
</script>

<style>
.fs-20 p { font-size: 20px; }
</style>
