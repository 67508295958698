<template>
  <div class="card card-page">
    <div class="card-body shadow">
      <WizardNavigator :steps="steps"/>
    </div>
  </div>
</template>

<script>
import WizardNavigator from '@/components/Quotation/WizardNavigator';

export default {
  name: "CivilResponsability",
  components: {
    WizardNavigator,
  },
  data: () => ({
    steps: ["Seus dados", "Outro step", "Final"],
  }),
};
</script>

<style>
</style>
