<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form
      @submit.stop.prevent="handleSubmit(quotation)"
      class="form"
      autocomplete="off"
    >
      <div class="container container-cozy">
        <div class="row">
          <div class="col-12 text-center">
            <header class="mb-5">
              <h2>
                <i class="fa fa-check-circle text-primary"></i> Pronto!<br />
                <small>Recebemos com sucesso suas informações.</small>
              </h2>
            </header>
          </div>
          <div v-if="alertMessage" class="col-12">
            <div class="alert-custom alert-danger-custom">
              {{ alertMessage }}
            </div>
          </div>
          <div class="col-12">
            <div class="d-table mx-auto">
              <hr />

              <p>
                Em breve você receberá a cotação no e-mail e/ou WhatsApp
                indicados.<br />
                Para casos urgentes, por favor, nos sinalize através dos
                contatos abaixo.
              </p>

              <p>
                A sua cotação passará por uma análise e entraremos em contato
                por WhatsApp,<br />
                telefone e/ou e-mail para falarmos a respeito dela.
              </p>

              <hr />

              <p>
                <strong
                  >Aqui estão os dados do especialista em seguro fiança
                  locatícia, caso precise de urgência:</strong
                >
              </p>

              <div class="card shadow d-table mx-auto border">
                <div class="card-body">
                  <p class="mb-0">
                    <i class="fa fa-fw fa-envelope"></i>
                    <a :href="'mailto:' + contact.email">
                      {{ contact.email }}
                    </a><br />
                    <i class="fab fa-fw fa-whatsapp"></i>
                    <a :href="'https://api.whatsapp.com/send?l=pt&amp;phone=' + ('+55 ' + contact.telephone).replace(/\D/g,'')" target="_blank">
                      {{ contact.telephone }}
                    </a><br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-table mx-auto">
          <div class="col-12 col-lg-12 mt-5">
            <b-button @click="home"
              class="btn btn-primary btn-lg text-uppercase px-4"
              >Acessar Painel <i class="fa fa-angle-right ml-2"></i
            ></b-button>
          </div>
        </div>
      </div>
    </b-form>
    <div
      v-if="loading"
      class="overlay d-flex align-items-center justify-content-center"
    >
      <b-spinner></b-spinner>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import rentalGuaranteeService from "@/services/rentalGuarantee";
import uiHelperMixin from "@/mixins/uiHelper";
import enumContactInfo from "@/enums/contactInfo";

export default {
  name: "FinalRenterGuaranteeQuotation",
  mixins: [uiHelperMixin],
  props: {
    renterQuotationId: {
      type: String
    }
  },
  components: {
    ValidationObserver
  },
  data: () => ({
    dataForm: {},
    contact: {
      name: enumContactInfo.APP_FINN_NAME,
      email: enumContactInfo.APP_FINN_EMAIL,
      telephone: enumContactInfo.APP_FINN_TELEPHONE
    },
    loading: false,
    focus: false,
    validate: false
  }),
  created() {
    if (this.renterQuotationId) {
      this.getQuotation(this.renterQuotationId);
    }
    this.scroll();
  },
  methods: {
    getQuotation(uid) {
      rentalGuaranteeService
        .getTermByUID(uid)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.fillFields(response.data, true);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a cotação.");
        });
    },

    home() {
      this.$router.push({ name: "quotation" });
    },

    fillFields(element) {
      this.dataForm.quotation_id = element.quotation_id;

      this.setFocus();
    },
    setFocus() {
      this.focus = true;
      this.focus = false;
    }
  }
};
</script>

<style></style>
