<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
    <b-form
      @submit.stop.prevent="handleSubmit(quotation)"
      class="form"
      autocomplete="off"
    >
      <div class="container container-cozy">
        <div class="row">
          <div class="col-12 text-center">
            <header class="mb-5">
              <h1 class="text-title-step">
                Vigência do contrato
              </h1>
            </header>
          </div>
          <div v-if="alertMessage" class="col-12">
            <div class="alert-custom alert-danger-custom">
              {{ alertMessage }}
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-4 mb-2">
                <label class="dbo-ui">Início da vigência</label>
                <validation-provider
                  name="início da vigência"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-input
                    v-model="dataForm.beginning_term"
                    type="date"
                    :state="getValidationState(validationContext)"
                    placeholder=""
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-4 mb-2">
                <label class="dbo-ui">Término da vigência</label>
                <validation-provider
                  name="término da vigência"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-input
                    v-model="dataForm.end_term"
                    type="date"
                    :state="getValidationState(validationContext)"
                    placeholder=""
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-4 mb-2">
                <label class="dbo-ui">Dias de vigência</label>
                <validation-provider
                  name="dias de vigência"
                  rules="required"
                  v-slot="validationContext"
                  vid="effective_days"
                  v-model="dataForm.effective_days"
                >
                  <b-form-input
                    v-model="dataForm.effective_days"
                    :state="getValidationState(validationContext)"
                    placeholder="XXX dias"
                    readonly
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mt-4 text-center topoT">
                <h5>Cobertura/Garantia</h5>
              </div>
              <div class="col-12 col-lg-1 mt-4 text-center topoT"></div>
              <div class="col-12 col-lg-5 mt-4 text-center topoT">
                <h5>Verba declarada</h5>
              </div>
              <div class="col-12 py-3 text-center bg-cinza">
                <b-spinner small v-if="loadingWarrantie"></b-spinner>
              </div>

              <b-container class="bv-example-row bg-cinza pb-4">
                <b-row
                  cols="3"
                  v-for="(cov, index) in coverageWarranties"
                  :key="index"
                  class="pb-4"
                >
                  <b-col cols="6" class="text-center">{{ cov.title }}</b-col>
                  <b-col cols="1" class="pt-3">
                    <b-form-checkbox
                      v-model="checked[cov.text]"
                      :value="cov.text"
                      name="check-button"
                      @change="checkStatus(cov.id, $event)"
                      switch
                    >
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="5" v-if="checked[cov.text]">
                    <validation-provider
                      :name="cov.title"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <money
                        v-bind="money"
                        v-model="dataForm.warrantie[cov.id]"
                        :state="getValidationState(validationContext)"
                        class="form-control"
                      />
                      <b-form-invalid-feedback>{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col cols="5" v-else>
                    <b-form-input
                      v-model="dataForm.warrantie[cov.text]"
                      :placeholder="cov.title"
                      disabled
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-container>

              <div class="col-12 mb-3 mt-4">
                <h1 class="text-title-step">
                  Vigência do contrato
                </h1>
              </div>

              <div class="col-12 col-lg-4 mb-3">
                <b-form-checkbox
                  id="property_damage"
                  v-model="dataForm.property_damage"
                  name="property_damage"
                  value="1"
                  unchecked-value="0"
                >
                  Danos ao imóvel
                </b-form-checkbox>
              </div>

              <div class="col-12 col-lg-4 mb-3">
                <b-form-checkbox
                  id="contractual_fines"
                  v-model="dataForm.contractual_fines"
                  name="contractual_fines"
                  value="1"
                  unchecked-value="0"
                >
                  Multas contratuais
                </b-form-checkbox>
              </div>

              <div class="col-12 col-lg-4 mb-3">
                <b-form-checkbox
                  id="external_painting"
                  v-model="dataForm.external_painting"
                  name="external_painting"
                  value="1"
                  unchecked-value="0"
                >
                  Pintura externa
                </b-form-checkbox>
              </div>

              <div class="col-12 col-lg-4 mb-3">
                <b-form-checkbox
                  id="internal_painting"
                  v-model="dataForm.internal_painting"
                  name="internal_painting"
                  value="1"
                  unchecked-value="0"
                >
                  Pintura interna
                </b-form-checkbox>
              </div>

              <div class="col-12 my-3" v-if="getParamEnumObjByEnumKey(docTaxa, dataForm.tax_option, 'title')">
                <b-card header-tag="header" footer-tag="footer" class="shadow">
                  <template #header>
                    <h2 class="mb-0">
                      Documentos para {{ getParamEnumObjByEnumKey(docTaxa, dataForm.tax_option, 'title') }}
                    </h2>
                  </template>
                  <b-card-text>
                    <div
                      v-html="getParamEnumObjByEnumKey(docTaxa, dataForm.tax_option, 'text')"
                    ></div>
                  </b-card-text>
                </b-card>
              </div>

              <div class="col-12 col-lg-12 mb-3">
                <!-- <label class="dbo-ui" for="">Documentos para análise</label> -->
                <upload-data
                  ref="upload"
                  :files="files"
                  :url="urlUpload"
                  :forceRedirect="false"
                />
              </div>

              <div class="col-12 col-lg-6 mb-3"></div>

              <div class="col-12 col-lg-12 mt-5">
                <button
                  :disabled="invalid"
                  class="button btn btn-primary btn-pretty icon-right"
                >
                  Finalizar <i class="fa fa-angle-right ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
    <div
      v-if="loading"
      class="overlay d-flex align-items-center justify-content-center"
    >
      <b-spinner></b-spinner>
    </div>
  </validation-observer>
</template>

<script>
import { UploadData } from "@/components";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import rentalGuaranteeService from "@/services/rentalGuarantee";
import quotationService from "@/services/quotation";
import { extend } from "vee-validate";
import uiHelperMixin from "@/mixins/uiHelper";
import dateFormatter from "@/mixins/dateFormatter";
import commonsService from "@/services/commons";
import docTaxa from "@/enums/translate/taxTypes";

extend("fullname", value => {
  const nameParts = value.split(" ");
  return nameParts.length > 1 || "Você deve informar seu nome completo";
});

export default {
  name: "PropertyDesired",
  mixins: [uiHelperMixin, dateFormatter],
  props: {
    renterQuotationId: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    UploadData
  },
  directives: {
    mask
  },
  data() {
    return {
      dataForm: {
        warrantie: {}
      },
      loading: false,
      focus: false,
      checked: {},
      validate: false,
      loadingWarrantie: false,
      coverageWarranties: {},
      files: [],
      urlUpload: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " ",
        precision: 2,
        masked: false
      },
      docTaxa: docTaxa
    };
  },
  computed: {
    beginningTerm() {
      return this.dataForm.beginning_term;
    },
    endTerm() {
      return this.dataForm.end_term;
    }
  },
  created() {
    if (this.renterQuotationId) {
      this.getQuotation(this.renterQuotationId);
    }
    this.urlUpload = `/api/v1/rental-guarantee/${this.renterQuotationId}/rental-guarantee-files`;
    this.resetFields();
    this.findRenterQuotationFiles(this.renterQuotationId);
    this.getCoverageWarrantie();
    this.scroll();
  },
  methods: {
    async getQuotation(uid) {
      await rentalGuaranteeService
        .getTermByUID(uid)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.fillFields(response.data, true);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a cotação.");
        });
    },
    getCoverageWarrantie: function() {
      this.loadingWarrantie = true;
      commonsService
        .coverageWarrantie()
        .then(response => {
          this.resetAlert();
          this.coverageWarranties = response.data;
        })
        .catch(() => {
          this.showError(
            "Não foi possível encontrar os dados de Cobertura/Garantia."
          );
        })
        .finally(() => {
          this.loadingWarrantie = false;
        });
    },
    findRenterQuotationFiles(uid) {
      rentalGuaranteeService
        .getRentalFiles(uid)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.fillFilesQuotation(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os arquivos da cotação.");
        });
    },
    fillFilesQuotation(files) {
      files.forEach(item => {
        this.$refs.upload.$refs.dropzone.manuallyAddFile(
          { name: item.name, id: item.id },
          ""
        );
      });
    },
    deleteFile(fileId) {
      this.resetAlert();
      rentalGuaranteeService
        .deleteRentalFile(this.renterQuotationId, fileId)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível deletar o arquivo.");
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    storeTerm() {
      return rentalGuaranteeService.storeTerm(this.dataForm);
    },
    updateTerm() {
      return rentalGuaranteeService.updateTerm(
        this.renterQuotationId,
        this.dataForm
      );
    },
    quotation() {
      this.resetAlert();
      this.loading = true;
      let savePromise = this.dataForm.id
        ? this.updateTerm()
        : this.storeTerm();
      savePromise
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.$refs.upload.$refs.dropzone.processQueue();
          var uid = response.data.id;

          quotationService
            .conclusionQuotation(uid)
            .then((response) => {
              if (response.status != 200) {
                this.showError(response.data.message);
                return;
              }
              this.loading = false;
              this.$router.push({
                name: "final-renter-guarantee-quotation",
                params: { renterQuotationId: uid }
              });
            })
            .catch(() => {
              this.showError("Não foi possível concluir a cotação.");
              this.scroll();
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.loading = false;
          this.showError("Não foi possível executar a cotação.");
        })
    },
    checkStatus(element, event) {
      if (!event) delete this.dataForm.warrantie[element];
    },
    fillFields(element) {
      this.dataForm.tax_option = element.suitor_guarantees.tax_option;
      if(element.rental_guarantee){
        this.dataForm.id = element.rental_guarantee.quotation_id;
        this.dataForm.beginning_term = element.rental_guarantee.beginning_term;
        this.dataForm.end_term = element.rental_guarantee.end_term;
        this.dataForm.effective_days = element.rental_guarantee.effective_days;
        this.dataForm.contractual_fines = element.rental_guarantee.contractual_fines;
        this.dataForm.external_painting = element.rental_guarantee.external_painting;
        this.dataForm.internal_painting = element.rental_guarantee.internal_painting;
        this.dataForm.property_damage = element.rental_guarantee.property_damage;
      }
      if(element.coverage_warranties){
        this.dataForm.warrantie = element.coverage_warranties.reduce(
          (obj, item) => ((obj[item.coverage_warrantie_id] = item.value), obj),
          {}
        );
        this.checked = element.coverage_warranties.reduce(
          (obj, item) => ((obj[item.text] = item.text), obj),
          {}
        );
      }

      this.setFocus();
    },
    setFocus() {
      this.focus = true;
      this.focus = false;
    },
    resetFields() {
      this.dataForm = {
        warrantie: this.dataForm.warrantie,
        beginning_term: this.dataForm.beginning_term,
        end_term: this.dataForm.end_term,
        quotation_id: this.renterQuotationId
      };
      this.setFocus();
    },
    getParamEnumObjByEnumKey(myEnum, enumValue, param) {
      let key = Object.values(myEnum).filter(x => x.id == enumValue);
      return key.length > 0 ? key[0][param] : null;
    },
  },
  watch: {
    endTerm() {
      if (this.dataForm.beginning_term) {
        this.dataForm.effective_days = this.calculateQuantityDays(
          this.dataForm.beginning_term,
          this.dataForm.end_term
        );
      }
    },
    beginningTerm() {
      if (this.dataForm.end_term)
        this.dataForm.effective_days = this.calculateQuantityDays(
          this.dataForm.beginning_term,
          this.dataForm.end_term
        );
    }
  }
};
</script>

<style></style>
