export default {
  "Aposentado/Pensionista": 1,
  "Funcionário com registro CLT": 2,
  "Autonômo": 3,
  "Empresário": 4,
  "Profissional liberal": 5,
  "Estudante": 6,
  "Funcionário Público": 7,
  "Renda proveniente de aluguéis": 8,
};
