<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
    <b-form
      @submit.stop.prevent="handleSubmit(quotation)"
      class="form"
      autocomplete="off"
    >
      <div class="container container-cozy">
        <div class="row">
          <div class="col-12 text-center">
            <header class="mb-5">
              <h2 class="text-title-step">
                Dados do imóvel pretendido
              </h2>
              <p>Informe abaixo os dados do imóvel pretendido</p>
            </header>
          </div>
          <div v-if="alertMessage" class="col-12">
            <div class="alert-custom alert-danger-custom">
              {{ alertMessage }}
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-4 mb-2">
                <label class="dbo-ui">CEP</label>
                <validation-provider
                  name="cep"
                  rules="required"
                  v-slot="validationContext"
                  vid="zipcode"
                  v-model="dataForm.zipcode"
                >
                  <b-form-input
                    v-mask="cepMask"
                    v-model="dataForm.zipcode"
                    :state="getValidationState(validationContext)"
                    placeholder="CEP do endereço atual"
                    @change="searchFullCep(dataForm.zipcode)"
                  ></b-form-input>
                  <b-spinner small v-if="loadingCep"></b-spinner>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-8 mb-3">
                <label class="dbo-ui">Endereço</label>
                <validation-provider
                  name="address"
                  rules="required"
                  v-slot="validationContext"
                  vid="address"
                  v-model="dataForm.address"
                >
                  <b-form-input
                    v-model="dataForm.address"
                    :state="getValidationState(validationContext)"
                    readonly
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-5 mb-3">
                <validation-provider
                  name="Bairro"
                  rules="required"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui">Bairro</label>
                  <b-form-input
                    :autofocus="focus"
                    v-model="dataForm.district"
                    :state="getValidationState(validationContext)"
                    ref="district"
                    readonly
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-5 mb-3">
                <validation-provider
                  name="Cidade"
                  rules="required"
                  v-slot="validationContext"
                  vid="city_id"
                >
                  <label class="dbo-ui">Cidade</label>
                  <select class="form-control" v-model="dataForm.city_id">
                    <option
                      v-for="(city, index) in cities"
                      :key="city"
                      :value="index"
                    >
                      {{ city }}
                    </option>
                  </select>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-2 mb-3">
                <validation-provider
                  name="Estado"
                  rules="required"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui">Estado</label>
                  <select
                    @change="findCities(dataForm.state_id)"
                    class="form-control"
                    v-model="dataForm.state_id"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.uf }}
                    </option>
                  </select>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-3 mb-3">
                <validation-provider
                  name="Número"
                  rules="required"
                  v-slot="validationContext"
                  vid="number"
                >
                  <label class="dbo-ui">Número</label>
                  <b-form-input
                    v-model="dataForm.number"
                    :state="getValidationState(validationContext)"
                    ref="number"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-5 mb-3">
                <validation-provider
                  name="Complemento"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui">Complemento</label>
                  <b-form-input
                    v-model="dataForm.complement"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mb-3"></div>

              <div class="col-12 col-lg-12 mt-5">
                <button
                  :disabled="invalid"
                  class="button btn btn-primary btn-pretty icon-right"
                >
                  Informar dados <i class="fa fa-angle-right ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
    <div
      v-if="loading"
      class="overlay d-flex align-items-center justify-content-center"
    >
      <b-spinner></b-spinner>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import rentalGuaranteeService from "@/services/rentalGuarantee";
import { extend } from "vee-validate";
import commonsService from "@/services/commons";
import uiHelperMixin from "@/mixins/uiHelper";
import searchCep from '@/mixins/typesAnalytics/searchCep';

extend("fullname", value => {
  const nameParts = value.split(" ");
  return nameParts.length > 1 || "Você deve informar seu nome completo";
});

export default {
  name: "PropertyDesired",
  mixins: [uiHelperMixin, searchCep],
  props: {
    renterQuotationId: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  directives: {
    mask
  },
  data: () => ({
    dataForm: {},
    loading: false,
    focus: false,
    validate: false,
    loadingCep: false,
    states: null,
    cities: null
  }),
  computed: {
    cepMask() {
      return "#####-###";
    }
  },
  created() {
    if (this.renterQuotationId) {
      this.getQuotation(this.renterQuotationId);
    }
    this.findStates();
    this.scroll();
    this.resetFields();
  },
  methods: {
    async getQuotation(uid) {
      await rentalGuaranteeService
        .getPropertyByUID(uid)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          if (response.data.coverage_guarantee) this.fillFields(response.data, true);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os dados do imóvel.");
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    findStates() {
      this.resetAlert();
      commonsService
        .states()
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.states = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os estados.");
        });
    },
    async findCities(state_id) {
      this.resetAlert();
      await commonsService
        .cities(state_id)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.cities = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cidades.");
        });
    },
    storeQuotation() {
      return rentalGuaranteeService.storeIntended(this.dataForm);
    },
    updateQuotation() {
      return rentalGuaranteeService.updateIntended(
        this.renterQuotationId,
        this.dataForm
      );
    },
    quotation() {
      this.resetAlert();
      this.loading = true;
      let savePromise = this.dataForm.id
        ? this.updateQuotation()
        : this.storeQuotation();
      savePromise
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          var uid = response.data.quotation_id;
          this.$router.push({
            name: "contract-vilidity",
            params: { renterQuotationId: uid }
          });
        })
        .catch(() => {
          this.showError("Não foi possível executar a cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fillFields(element) {
      this.dataForm.id = element.coverage_guarantee.id;
      this.dataForm.address = element.coverage_guarantee.address;
      this.dataForm.number = element.coverage_guarantee.number;
      this.dataForm.zipcode = element.coverage_guarantee.zipcode;
      this.dataForm.state_id = element.coverage_guarantee.state_id;
      this.findCities(this.dataForm.state_id);
      this.dataForm.city_id = element.coverage_guarantee.city_id;
      this.dataForm.district = element.coverage_guarantee.district;
      this.dataForm.complement = element.coverage_guarantee.complement;
      this.quotation_id = this.renterQuotationId;
      this.setFocus();
    },
    setFocus() {
      this.focus = true;
      this.focus = false;
    },

    resetFields() {
      this.dataForm = {
        address: this.dataForm.address,
        number: this.dataForm.number,
        zipcode: this.dataForm.zipcode,
        state_id: this.dataForm.state_id,
        city_id: this.dataForm.city_id,
        district: this.dataForm.district,
        complement: this.dataForm.complement,
        quotation_id: this.renterQuotationId
      };
      this.setFocus();
    }
  },
};
</script>

<style></style>
