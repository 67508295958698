<template>
  <div class="container container-cozy">
    <div class="row">
      <div class="col-12 col-lg-12">
        <header class="mb-5 text-center" id="header">
          <h2>Informe os dados da garantia</h2>
          <p class="mb-0">{{ quotation_category ? quotation_category.name : '' }}</p>
        </header>
      </div>
    </div>

    <div v-if="alertMessage" class="col-12">
      <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>

    <section id="section-quotation">
      <div class="form-group mb-3">
        <label class="dbo-ui" for="">CNPJ Tomador</label>
        <div class="input-group">
          <div class="form-control truncate"><strong>{{ quotationForm.name }}</strong> - {{ formatCnpj(quotationForm.document) }}</div>
          <div class="input-group-append">
            <button type="button" class="button btn btn-primary"
               @click="$bvModal.show('show-modal-document')"
            ><i class="fa fa-pencil-alt"></i><span class="d-none d-md-inline-block ml-2"> Alterar</span></button>
          </div>
        </div>
      </div>
    </section>

    <section v-if="!isJudicialInsurance" id="section-tipo-garantia">
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form
          @submit.stop.prevent="handleSubmit(quotation)"
          class="form"
          autocomplete="off"
        >
          <div class="row">
            <div class="col-12 col-lg-4 mb-3" v-if="!isTransitOrTemporaryAdmission()">
              <label class="dbo-ui nowrap" for="">Selecione abaixo o tipo de contrato</label>
              <div
                class="list-group list-group-horizontal mb-3 text-center"
                id="list-tab"
              >
                <span
                  @click="changeToPublicType"
                  :class="{ active: publicSelected }"
                  class="pointer list-group-item list-group-item-action"
                  id="list-home-list"
                  >Público</span
                >
                <span
                  @click="changeToPrivateType"
                  :class="{ active: privateSelected }"
                  class="pointer list-group-item list-group-item-action"
                  id="list-profile-list"
                  >Privado</span
                >
              </div>
              <div v-if="showErrorContract" class="invalid-feedback-custom">Selecione o tipo de contrato</div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-4 mb-3"  
              v-if="insurance_category_id != insuranceCategoryTypes.CUSTOMS_TRANSIT &&
                    insurance_category_id != insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION &&
                    insurance_category_id != insuranceCategoryTypes.ENERGY_PAYMENT">
              <validation-provider
                name="valor do contrato"
                rules="required"
                v-slot="validationContext"
              >
                <label class="dbo-ui">Valor do Contrato (em R$)</label>
                <money
                  v-bind="money"
                  v-model="dataForm.contract_value"
                  :state="getValidationState(validationContext)"
                  @keypress.native="getValidationLess($event)"
                  class="form-control"
                />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
            <div class="col-12 col-lg-4 mb-3" 
              v-if="insurance_category_id != insuranceCategoryTypes.CUSTOMS_TRANSIT &&
                    insurance_category_id != insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION &&
                    insurance_category_id != insuranceCategoryTypes.ENERGY_PAYMENT">
              <validation-provider
                name="porcentagem da garantia"
                rules="required"
                v-slot="validationContext"
              >
                <label class="dbo-ui">% da garantia</label>
                <span
                  v-b-tooltip.hover
                  title="
                    Utilize o ponto para valores contendo casas decimais.
                    Exemplo: 3.14%
                  "
                  class="ml-2">
                  <i class="fas fa-question-circle text-dark"></i>
                </span>
                <!-- <money
                  :disabled="disabled_guarantee_percent"
                  v-bind="percent"
                  v-model="dataForm.guarantee_percent"
                  :state="getValidationState(validationContext)"
                  @keypress.native="getValidationLess($event)"
                  class="form-control"
                  id="fname"
                /> -->

                <b-input-group size="" prepend="" append="%">
                  <vue-regex-input
                    v-model="dataForm.guarantee_percent"
                    :regExp="/^\d{0,3}(\.\d{0,20})?$/g"
                    :state="getValidationState(validationContext)"
                    @keypress.native="getValidationLess($event)"
                    class="form-control"
                    placeholder="0.00"
                  ></vue-regex-input>
                </b-input-group>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-8 mb-3">
              <validation-provider
                name="valor da garantia"
                rules="required"
                v-slot="validationContext"
              >
                <label class="dbo-ui">Valor da garantia</label>
                <money
                  v-bind="money"
                  v-model="dataForm.guarantee_value"
                  :state="getValidationState(validationContext)"
                  @keypress.native="getValidationLess($event)"
                  class="form-control"
                />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-4 mb-3">
              <validation-provider
                name="data início"
                rules="required"
                v-slot="validationContext"
              >
                <label class="dbo-ui">Quando deve iniciar?</label>
                <b-form-input
                  v-model="dataForm.guarantee_start"
                  type="date"
                  :state="getValidationState(validationContext)"
                  placeholder=""
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-4 mb-3">
              <validation-provider
                name="quantidade de dias"
                rules="required"
                v-slot="validationContext"
              >
                <label class="dbo-ui">Quantidade de dias</label>
                <b-form-input
                  :disabled="disabled_quantity_days"
                  v-model="dataForm.quantity_days"
                  type="number"
                  :state="getValidationState(validationContext)"
                  @keypress.native="getValidationLess($event)"
                  placeholder=""
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-4 mb-3">
              <validation-provider
                name="data final"
                rules="required"
                v-slot="validationContext"
              >
                <label class="dbo-ui">Finaliza em?</label>
                <b-form-input
                  :disabled="disabled_guarantee_end"
                  v-model="dataForm.guarantee_end"
                  type="date"
                  :state="getValidationState(validationContext)"
                  placeholder=""
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div class="row">
             <div v-if="checkInsuranceCategory && !isCustoms" class="col-12 col-lg-12 mb-3">
              <div class="custom-control custom-switch mb-3">
                <input
                  :false-value="0"
                  :true-value="1"
                  v-model="dataForm.labor_coverage"
                  type="checkbox"
                  class="custom-control-input"
                  id="switch-cobertura-trabalhista"
                />
                <label
                  class="custom-control-label"
                  for="switch-cobertura-trabalhista"
                  >Incluir cobertura trabalhista</label
                >
              </div>
            </div>
            <!-- Comentando até ter certeza de como é isso em outras seguradoras -->
            <!-- <div class="col-12 col-lg-12 mb-3">
              <div class="custom-control custom-switch mb-3">
                <input
                  :false-value="0"
                  :true-value="1"
                  v-model="dataForm.penalty_coverage"
                  type="checkbox"
                  class="custom-control-input"
                  id="switch-cobertura-multa"
                />
                <label class="custom-control-label" for="switch-cobertura-multa"
                  >Incluir cobertura para multa</label
                >
              </div>
            </div> -->
          </div>

          <div class="row">
            <div class="col-12 col-lg-12 mt-5">
              <span
                @click="back()"
                class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
                ><i class="fa fa-angle-left mr-2"></i
                ><span class="d-none d-md-inline-block">Voltar</span></span
              >
              <button
                :disabled="invalid || checkValues"
                class="btn btn-primary btn-lg text-uppercase px-4"
              >
                Preencha os dados do segurado <i class="fa fa-angle-right ml-2"></i>
              </button>
              <span v-b-tooltip.hover title="Necessário preencher campos obrigatórios para avançar." class="ml-2" v-if="invalid || checkValues">
                <i class="fas fa-exclamation-circle text-warning"></i>
              </span>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </section>

    <judicial-guarantee-data :quotationCategory="quotation_category" :dataJudicial="dataJudicial" v-else/>

    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center"><b-spinner></b-spinner></div>
    
    <select-company :selectedCompany="selectedCompany" ref="modalHolder" />

  </div>
</template>

<script>
import { JudicialGuaranteeData } from '@/components/Quotation';
import { SelectCompany } from '@/components/Company';
import contractTypes from "@/enums/contractTypes";
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import enumContactInfo from "@/enums/contactInfo";
import quotationService from "@/services/quotation";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import dateFormatter from '@/mixins/dateFormatter';
import uiHelperMixin from '@/mixins/uiHelper';
import commomsHelperMixin from '@/mixins/commomsHelper';
import { mask } from "vue-the-mask";

export default {
  name: "GaranteeData",
  mixins: [uiHelperMixin, dateFormatter, commomsHelperMixin],
  props: {
    quotationId: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    JudicialGuaranteeData,
    SelectCompany
  },
   directives: {
    mask
  },
  data: () => ({
    dataForm: { contract_type: null, guarantee_percent: 0 },
    quotationForm: {},
    companies: [],
    loading: false,
    insuranceCategoryTypes: insuranceCategoryTypes,
    insurance_category_id: null,
    disabled_guarantee_end: false,
    disabled_quantity_days: false,
    disabled_guarantee_percent: false,
    disabled_guarantee_value: false,
    quotation_category: null,
    checkInsuranceCategory: false,
    isJudicialInsurance: false,
    showErrorContract: false,
    dataJudicial:{},
    isCustoms: false,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    percent: {
      decimal: ".",
      thousands: "",
      prefix: "",
      suffix: "%",
      precision: 2,
      masked: false,
    },
    emailGarantia: enumContactInfo.APP_FINN_EMAIL_GUARANTEE
  }),
  computed: {
    allowedJudicialFlow() {
      return this.allowedJudicial()
    },
    allowedCustomsFlow() {
      return this.allowedCustoms()
    },
    quantityDays() {
      return this.dataForm.quantity_days
    },
    guaranteeStart() {
      return this.dataForm.guarantee_start
    },
    guaranteeEnd() {
      return this.dataForm.guarantee_end
    },
    guaranteePercent() {
      return this.dataForm.guarantee_percent
    },
    contractValue() {
      return this.dataForm.contract_value
    },
    valueGarantee() {
      return this.dataForm.guarantee_value
    },
    publicSelected() {
      return this.dataForm.contract_type == contractTypes.PUBLIC;
    },
    privateSelected() {
      return this.dataForm.contract_type == contractTypes.PRIVATE;
    },
    checkValues() {
      if (
        this.insurance_category_id == this.insuranceCategoryTypes.CUSTOMS_TRANSIT ||
        this.insurance_category_id == this.insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION ||
        this.insurance_category_id == this.insuranceCategoryTypes.ENERGY_PAYMENT 
      ) {
        return !this.quotationForm.document;
      }
      return (
        this.dataForm.contract_value == 0 ||
        this.dataForm.guarantee_percent == 0 ||
        !this.quotationForm.document
      );
    },
    notAllowedLaborCoverage() {
      return [
        this.insuranceCategoryTypes.RECEIVE_AN_ADVANCE_PAYMENT, 
        this.insuranceCategoryTypes.PARTICIPATE_IN_A_BID_OR_COMPETITION        
      ]
    },
    selectedCompany: function () {
      return {
        cnpj: this.quotationForm.document,
        company_name: this.quotationForm.name
      };
    }
  },
  created() {
    this.findQuotation(this.quotationId);
    this.dataForm.labor_coverage = 0;
    this.dataForm.penalty_coverage = 0;
    this.scroll();
  },
  methods: {
    getValidationLess(event) {
      return event.key === '-' ? event.preventDefault() : null
    },
    fillFields(element) {
      if (element != "") {
        this.dataForm.contract_type = element.contract_type;
        this.dataForm.contract_value = element.contract_value;
        this.dataForm.guarantee_percent = parseFloat(element.guarantee_percent);
        this.dataForm.guarantee_value = element.guarantee_value;
        this.disabled_guarantee_end = true;
        this.dataForm.labor_coverage = element.labor_coverage;
        this.dataForm.penalty_coverage = element.penalty_coverage;
        this.$set(this.dataForm, 'guarantee_start', element.guarantee_start.substr(0, 10));
        this.$set(this.dataForm, 'quantity_days', this.calculateQuantityDays(element.guarantee_end));
      }
    },
    findQuotation(uid) {
      this.loading = true;
      quotationService
        .getByUID(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.quotationForm = response.data;
          this.insurance_category_id = response.data.insurance_category_id;
          if (response.data.insurance_category) {
            this.quotation_category = response.data.insurance_category;
            this.dataForm.insurance_category_id = this.quotation_category.id;
            this.checkInsuranceCategory = this.notAllowedLaborCoverage.indexOf(this.quotation_category.id) == -1
            this.isJudicialInsurance = this.allowedJudicialFlow.indexOf(this.quotation_category.id) != -1
            this.isCustoms = this.allowedCustomsFlow.indexOf(this.quotation_category.id) != -1
          }
          
          if (!response.data.document) {
            if (this.companies.length == 1) {
              this.selectCompany(this.companies[0]);
            } else {
              this.openHolderModal();
            }
          }

          this.findGuarantee(this.quotationId);
        })
        .catch((err) => {
          console.error(err);
          this.showError("Não foi possível recuperar a cotação.");
        }).finally(() => {
          this.isTransitOrTemporaryAdmission() ? this.changeToPublicType(): '';
          this.loading = false;
        });
    },
    findGuarantee(uid) {
      this.loading = true;
      quotationService
        .getGuaranteeByUID(uid)
        .then((response) => {
          if (response.status == 200) {
            if(this.isJudicialInsurance){
              this.dataJudicial = response.data
              return
            }
            this.fillFields(response.data);
          }
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a garantia.");
        }).finally(() => {
          this.loading = false;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    changeToPublicType() {
      this.dataForm.contract_type = contractTypes.PUBLIC;
    },
    changeToPrivateType() {
      this.dataForm.contract_type = contractTypes.PRIVATE;
    },
    calculateQuantityDays(value) {
      var date1 = new Date(this.dataForm.guarantee_start);
      var date2 = new Date(value);
      var differenceInTime = date2.getTime() - date1.getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24));
    },
    checkGuaranteeType(){
      if(this.isJudicialInsurance){
        this.insurance_category_id == this.insuranceCategoryTypes.APPEAL_A_CIVIL_ACTION ||
        this.insurance_category_id == this.insuranceCategoryTypes.TAX_ENFORCEMENT ||
        this.insurance_category_id == this.insuranceCategoryTypes.I_HAVE_TAX_CREDIT_OR_INSTALLMENT
        ? this.dataForm.guarantee_percent = 0 
        : this.dataForm.guarantee_percent = 30;
        this.dataForm.contract_value = this.dataForm.guarantee_value + (this.dataForm.guarantee_value * (this.dataForm.guarantee_percent / 100))
        this.dataForm.labor_coverage = 0
        this.dataForm.penalty_coverage = 0
        this.dataForm.insurance_category_id = this.quotation_category.id;
        this.dataForm.guarantee_end = this.addYears(this.dataForm.guarantee_start, this.dataForm.quantity_years)
      }
    },
    checkContractType(){
      this.showErrorContract = false
      if(!this.contract_type){
        this.showErrorContract = true
      }
    },
    quotation(dataForm = null) {
      this.dataForm = dataForm ? dataForm : this.dataForm
      this.loading = true;
      this.checkGuaranteeType()
      this.checkContractType()
      this.resetAlert();
      
      quotationService
        .update(this.quotationId, this.quotationForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            this.loading = false;
            return;
          }

          quotationService
            .updateGuarantee(this.quotationId, this.dataForm)
            .then((response) => {
              if (response.data.error) {
                this.showError(response.data.message);
                this.loading = false;
                return;
              }
              this.$router.push({
                name: "insured-data",
                params: { 
                  quotationId: this.quotationId, 
                  quotationCategory: this.quotation_category.id 
                },
              });
            })
            .catch(() => {
              this.showError("Não foi possível gravar os dados da garantia.");
              this.loading = false;
            });
        })
        .catch(() => {
          this.showError("Não foi possível gravar os dados da cotação.");
          this.loading = false;
        });
      
    },
    calculatePercent(contract_value, guarantee_percent) {
      return contract_value * (guarantee_percent / 100);
    },
    calculateDateFinal(days, dateInitial) {
      var d = new Date(dateInitial);
      d.setDate(d.getDate() + 1);
      var dateFormat = new Date(d.getTime() + days * 24 * 60 * 60 * 1000);
      return this.formatDateISO(dateFormat);
    },
    calculatePercentValue(contract_value, guarantee_value) {
      return (guarantee_value * 100) / contract_value
    },
    back() {
      this.$router.push({
        name: "garantee-types-selection",
        params: { quotationId: this.quotationId },
      });
    },
    openHolderModal() {
      this.$refs.modalHolder.openHolderModal();
    },
    selectCompany(company) {
      this.quotationForm.document = company.cnpj;
      this.quotationForm.name = company.company_name;
    },
    isTransitOrTemporaryAdmission(){
      return this.insurance_category_id == this.insuranceCategoryTypes.CUSTOMS_TRANSIT ||
             this.insurance_category_id == this.insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION;
    }
  },
  watch: {
    quantityDays(value) {
      if (this.dataForm.guarantee_start) {
        this.dataForm.guarantee_end = this.calculateDateFinal(
          value,
          this.dataForm.guarantee_start
        );
        this.disabled_guarantee_end = true;
        this.disabled_quantity_days = false;
      }
    },
    guaranteeStart(value) {
      if (this.dataForm.quantity_days) {
        this.dataForm.guarantee_end = this.calculateDateFinal(
          this.dataForm.quantity_days,
          value
        );
      }
    },
    guaranteeEnd(value) {
      if (this.dataForm.guarantee_start) {
        this.dataForm.quantity_days = this.calculateQuantityDays(value);
        if(!this.disabled_guarantee_end) {
          this.disabled_quantity_days = true;
        }
      }
    },
    guaranteePercent() {
      if (this.dataForm.contract_value > 0 && !this.isJudicialInsurance) {
        this.dataForm.guarantee_value = this.calculatePercent(
          this.dataForm.contract_value,
          this.dataForm.guarantee_percent
        );
        if(!this.disabled_guarantee_percent) {
          this.disabled_guarantee_value = true;
        }
      }

    },
    contractValue() {
      if (this.dataForm.guarantee_percent > 0 && !this.isJudicialInsurance) {
        this.dataForm.guarantee_value = this.calculatePercent(
          this.dataForm.contract_value,
          this.dataForm.guarantee_percent
        );
      }
      if (this.dataForm.guarantee_value > 0  && !this.isJudicialInsurance) {
        this.dataForm.guarantee_percent = this.calculatePercentValue(
          this.dataForm.contract_value,
          this.dataForm.guarantee_value
        );
      }
    },
    valueGarantee() {
      if (this.dataForm.contract_value > 0) {
        this.dataForm.guarantee_percent = this.calculatePercentValue(
          this.dataForm.contract_value,
          this.dataForm.guarantee_value
        );
        if(!this.disabled_guarantee_value) {
          this.disabled_guarantee_percent = true;
          this.percent.precision = 4
        }
      }
    },
  },
};
</script>

<style>
  @import '../../../../styles/placeholder.css';
</style>
