<template>
  <div class="card card-page">
    <div class="card-body shadow">
      <WizardNavigator
        :steps="steps"
        :routeIndexes="routeIndexes"
        :identifier="renterQuotationId"
        identifierRouteKey="renterQuotationId"
      />
      <div class="container container-cozy">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import WizardNavigator from '@/components/Quotation/WizardNavigator';

export default {
  name: "RenterGuarantee",
  props: {
    renterQuotationId: {
      type: String
    },
  },
  components: {
    WizardNavigator,
  },
  data: () => ({
    steps: [
      "Dados do pretendente",
      "Dados do imóvel pretendido",
      "Vigência do contrato",
      "Final",
    ],
    routeIndexes: {
      'renter-guarantee-quotation': 1,
      'data-property-desired': 2,
      'contract-vilidity': 3,
      'final-renter-guarantee-quotation': 4
    }
  }),
};
</script>

<style>
</style>
