<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <header class="mb-5 text-center">
          <h2>Detalhes da garantia</h2>
          <p class="mb-0">
            {{getEnumKeyByEnumValue(translateInsuranceCategoryTypes, insuranceCategoryId)}}
          </p>
        </header>
      </div>
    </div>

    <div v-if="alertMessage" class="col-12">
      <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>

    <validation-observer v-if="!isJudicialInsurance && !isResourcesInsurance" ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form
        @submit.stop.prevent="handleSubmit(quotation)"
        class="form"
        autocomplete="off"
      >
        <div class="container container-cozy">
            <div class="row">
              <div class="col-12 col-lg-6 mb-3">
                <label class="dbo-ui" for=""
                  >Nº do Edital/Contrato/Pedido
                  <i
                    class="fa fa-question-circle"
                    v-b-tooltip.hover
                    title="Informe aqui o número do edital/contrato/pedido que exige a garantia. Este campo é parte fundamental para emissão de sua apólice."
                  ></i
                ></label>
                <validation-provider
                  name="número de edital/contrato/pedido"
                  rules="required"
                  v-slot="validationContext"
                  vid="contract_number"
                >
                  <b-form-input
                    :focus="focus"
                    v-model="dataForm.contract_number"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div v-if="checkInsuranceCategory" class="col-12 col-lg-6 mb-3">
                <label class="dbo-ui" for=""
                  > Nº do Evento/Parcela
                  <i
                    class="fa fa-question-circle"
                    v-b-tooltip.hover
                    title="Informe aqui o número do evento/parcela que exige a garantia. Este campo é parte fundamental para emissão de sua apólice."
                  ></i
                ></label>
                <validation-provider
                  name="número do evento/parcela"
                  rules="required"
                  v-slot="validationContext"
                  vid="installment_number"
                >
                  <b-form-input
                    type="text"
                    v-model="dataForm.installment_number"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-8 mb-3">
                <label class="dbo-ui" for=""
                  >Observações (Preenchimento Opcional)
                  <i
                    class="fa fa-question-circle"
                    v-b-tooltip.hover
                    title="Informe qualquer informação adicional sobre o seu pedido de garantia"
                  ></i
                ></label>
                <validation-provider
                  name="observações"
                  v-slot="validationContext"
                  vid="additional_notes"
                >
                  <b-form-textarea
                    rows="3"
                    v-model="dataForm.additional_notes"
                    :state="getValidationState(validationContext)"
                  ></b-form-textarea>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="col-12">
            <upload-data ref="upload"
              :files="files"
              :url="urlUpload"
              :forceRedirect="false"
              @deleteFile="deleteFile"
            />
            <div class="row">
              <div class="col-12 col-lg-12 mt-5">
                <button
                  @click="back()"
                  class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
                >
                  <i class="fa fa-angle-left mr-2"></i
                  ><span class="d-none d-md-inline-block"> Voltar</span>
                </button>
                <button
                  :disabled="invalid"
                  class="btn btn-primary btn-lg text-uppercase px-4"
                >
                  Avançar <i class="fa fa-angle-right ml-2"></i>
                </button>
                <span v-b-tooltip.hover title="Necessário preencher campos obrigatórios para avançar." class="ml-2" v-if="invalid">
                  <i class="fas fa-exclamation-circle text-warning"></i>
                </span>
              </div>
            </div>
        </div>
      </b-form>
    </validation-observer>

    <judicial-details-data
      ref="judicial"
      :quotationCategoryId="insuranceCategoryId"
      :dataJudicial="dataJudicial"
      :filesJudicial="filesJudicial"
      :urlUpload="urlUpload"
    v-else/>

    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center"><b-spinner></b-spinner></div>

  </div>

</template>

<script>
import { UploadData } from '@/components';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import translateInsuranceCategoryTypes from "@/enums/translate/insuranceCategoryTypes";
import { JudicialDetailsData } from '@/components/Quotation';
import { mask } from "vue-the-mask";
import quotationService from "@/services/quotation";
import uiHelperMixin from '@/mixins/uiHelper';
import commomsHelperMixin from '@/mixins/commomsHelper';

export default {
  name: "Details",
  mixins: [uiHelperMixin, commomsHelperMixin],
  props: {
    quotationId: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    JudicialDetailsData,
    UploadData
  },
  directives: {
    mask,
  },
  created() {
    this.findQuotation(this.quotationId);
    this.findQuotationFiles(this.quotationId);
    this.urlUpload = `/api/v1/quotations/${this.quotationId}/files`
    this.scroll();
  },
  computed: {
    allowedJudicialFlow() {
      return this.allowedJudicial()
    },
    allowedResources() {
      return [
        this.insuranceCategoryTypes.CUSTOMS,
        this.insuranceCategoryTypes.CUSTOMS_TRANSIT,
        this.insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION,
      ]
    }
  },
  data() {
    return {
      loading: false,
      is_destroying: false,
      dataJudicial: {},
      filesJudicial: [],
      isJudicialInsurance: false,
      focus: false,
      insuranceCategoryId: null,
      checkInsuranceCategory: false,
      insuranceCategoryTypes: insuranceCategoryTypes,
      translateInsuranceCategoryTypes: translateInsuranceCategoryTypes,
      dataForm: {},
      files: [],
      urlUpload: '',
      isResourcesInsurance: null,
    };
  },
  methods: {
    deleteFile(fileId) {
      this.resetAlert();
      quotationService
        .deleteQuotationFile(this.quotationId, fileId)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível deletar o arquivo.");
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    findQuotation(uid) {
      this.loading = true
      quotationService
        .getByUID(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.isJudicialInsurance = this.allowedJudicialFlow.indexOf(response.data.insurance_category_id) != -1
          this.isResourcesInsurance = this.allowedResources.indexOf(response.data.insurance_category_id) != -1
          this.insuranceCategoryId = response.data.insurance_category_id;
          
          if(this.isJudicialInsurance || this.isResourcesInsurance){
            this.dataJudicial = response.data
          }
          this.fillFields(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a cotação.");
        }).finally(() => {
          this.loading = false;
        });
    },
    findQuotationFiles(uid) {
      this.loading = true
      quotationService
        .getQuotationFiles(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          if(this.isJudicialInsurance){
            this.filesJudicial = response.data
            return
          }
          this.fillFilesQuotation(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os arquivos da cotação.");
        }).finally(() => {
          this.loading = false;
        });
    },
    fillFilesQuotation(files) {
      files.forEach((item) => {
        this.$refs.dropzone.manuallyAddFile({ name: item.name }, "");
      });
    },
    quotation(dataForm = null) {
      this.resetAlert();
      this.dataForm = dataForm ? dataForm : this.dataForm

      quotationService
        .updateGuarantee(this.quotationId, this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          if(this.isJudicialInsurance && this.insuranceCategoryId != this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT){
            this.$refs.judicial.$refs.upload.$refs.dropzone.processQueue()
          }else{
            this.$nextTick(() => {
              this.$refs.upload.$refs.dropzone.processQueue()
            });
          }
          this.$router.push({
                name: "quotation-summary",
                params: { quotationId: this.quotationId },
          });
        })
        .catch(() => {
          this.showError("Não foi possível atualizar a cotação.");
          this.isLoading = false;
          this.scroll();
        });
    },
    back() {
      this.$router.push({ 
        name: "insured-data", 
        params: { 
          quotationId: this.quotationId, 
          quotationCategory: this.insuranceCategoryId 
        } 
      });
    },
    fillFields(element) {
      this.dataForm.contract_number = element.guarantee.contract_number;
      this.dataForm.additional_notes = element.guarantee.additional_notes;
      this.dataForm.insurance_category_id = element.insurance_category_id;
      this.checkInsuranceCategory = this.insuranceCategoryTypes.RECEIVE_AN_ADVANCE_PAYMENT == this.dataForm.insurance_category_id
      if(this.checkInsuranceCategory){
        this.dataForm.installment_number = element.guarantee.installment_number;
      }
      this.setFocus();
    },
    setFocus() {
      this.focus = true;
      this.focus = false;
    },
  },
};
</script>

<style>
</style>
