<template>
  <div class="card card-page">
    <div class="card-body shadow">
      <WizardNavigator
        :steps="steps"
        :routeIndexes="routeIndexes"
        :identifier="quotationId"
        identifierRouteKey="quotationId"
      />
      <div class="container container-cozy">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import WizardNavigator from '@/components/Quotation/WizardNavigator';

export default {
  name: "Guarantee",
  props: {
    quotationId: {
      type: String
    },
  },
  components: {
    WizardNavigator,
  },
  data: () => ({
    steps: [
      "Tipo de garantia",
      "Dados da garantia",
      "Dados do segurado",
      "Detalhes da garantia",
      "Resumo da cotação",
      "Final",
    ],
    routeIndexes: {
      'garantee-types-selection': null,
      'garantee-data': 2,
      'insured-data': 3,
      'quotation-details': 4,
      'quotation-summary': 5,
      'final-feedback-quotation': 6
    }
  }),
};
</script>

<style>
</style>
