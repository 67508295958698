<template>
  <div class="container container-cozy">
    <div class="row">
      <div class="col-12 col-lg-12">
        <header class="mb-5 text-center">
          <h2>Dados do {{ sufixStep }}</h2>
          <p class="mb-0">Informe os dados do <span class="lowercase">{{ sufixStep }}</span></p>
        </header>
      </div>
    </div>

    <div v-if="alertMessage">
      <div class="alert-custom alert-danger-custom list-errors-custom">
        <ul class="pt-3 list-errors" v-if="Array.isArray(alertMessage)">
          <li v-for="er in alertMessage" :key="er">{{ er[0] }}</li>
        </ul>
        <div v-else>
          {{ alertMessage }}
        </div>
      </div>
    </div>

    <section id="section-dados-segurado">
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form
          @submit.stop.prevent="handleSubmit(quotation)"
          class="form"
          autocomplete="off"
        >
           <div class="row">
            <div class="col-12 col-lg-8 mb-3">
              <label class="dbo-ui">Tipo do {{ sufixStep }}</label>

              <div
                class="list-group list-group-horizontal mb-3 text-center"
                id="list-tab"
                role="tablist"
              >
                <span
                  @click="changeToNaturalType"
                  :class="{ active: naturalSelected && !isCustomsInsurance, disabled: isCustomsInsurance}"
                  class="pointer list-group-item list-group-item-action"
                  id="list-home-list"
                  >Pessoa física</span
                >
                <span
                  @click="changeToLegalType"
                  :class="{ active: legalSelected }"
                  class="pointer list-group-item list-group-item-action"
                  id="list-profile-list"
                  >Pessoa jurídica</span
                >
              </div>
            </div>
          </div>

          <div class="row align-items-end">
            <div class="col-12 col-lg-8 mb-3">
              <label class="dbo-ui">{{ documentLabel }} {{ sufixDocument }}</label>
              <validation-provider
                name="documento"
                :rules="rulesDocument"
                v-slot="validationContext"
                vid="document"
              >
                <b-form-input
                  v-model="dataForm.document"
                  :state="getValidationState(validationContext)"
                  v-mask="documentMask"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
            <div class="col-12 col-lg-4 mb-auto query-button text-center text-lg-left">
              <button
                :disabled="loadingCnpj"
                v-if="legalSelected"
                @click="findCnpj(dataForm.document)"
                type="button"
                class="button btn btn-primary"
              >
                <b-spinner v-if="loadingCnpj" small></b-spinner>
                <i v-else class="fa fa-fw fa-search"></i> Consultar dados
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-12 mb-3">
              <label class="dbo-ui">{{ nameLabel }} {{ sufixDocument }}</label>
              <validation-provider
                name="nome"
                rules="required|max:191|fullname"
                v-slot="validationContext"
                vid="name"
              >
                <b-form-input
                  :focus="focus"
                  v-model="dataForm.name"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

						<div class="col-12 col-lg-6 mb-3">
							<label class="dbo-ui">CEP</label>
							<validation-provider
                name="cep"
                rules="required"
                v-slot="validationContext"
                vid="zipcode"
                v-model="dataForm.zipcode"
              >
                <b-form-input
                  v-mask="cepMask"
                  v-model="dataForm.zipcode"
                  :state="getValidationState(validationContext)"
                  @change="searchCep"
                ></b-form-input>
                <b-spinner small v-if="loadingCep"></b-spinner>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

						<div class="col-12 col-lg-6 mb-3">
							<label class="dbo-ui">Bairro</label>
							<validation-provider
                name="bairro"
                rules="required|max:100"
                v-slot="validationContext"
                vid="district"
                v-model="dataForm.district"
              >
                <b-form-input
                  v-model="dataForm.district"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

						<div class="col-12 col-lg-6 mb-3">
							<label class="dbo-ui">Endereço</label>
							<validation-provider
                name="endereço"
                rules="required|max:191"
                v-slot="validationContext"
                vid="address"
              >
                <b-form-input
                  v-model="dataForm.address"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
						</div>

						<div class="col-12 col-lg-2 mb-3">
							<label class="dbo-ui">Número</label>
							<validation-provider
                name="número"
                rules="required|max:6"
                v-slot="validationContext"
                vid="address_number"
              >
                <b-form-input
                  v-model="dataForm.address_number"
                  :state="getValidationState(validationContext)"
                  ref="address_number"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
						</div>

						<div class="col-12 col-lg-4 mb-3">
							<label class="dbo-ui">Complemento (opcional)</label>
							<validation-provider
                name="complemento"
                rules=""
                v-slot="validationContext"
                vid="address_line_2"
              >
                <b-form-input
                  v-model="dataForm.address_line_2"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
						</div>

						<div class="col-12 col-lg-6 mb-3">
							<label class="dbo-ui">Estado</label>
              <validation-provider
              name="estado"
              rules="required">
                <div slot-scope="ProviderProps">
                  <select
                    @change="findCities(dataForm.state_id)"
                    class="form-control"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="dataForm.state_id"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                  <p class="invalid-feedback-custom">
                    {{ ProviderProps.errors[0] }}
                  </p>
                </div>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="dbo-ui">Cidade</label>
              <validation-provider name="cidade" rules="required">
                <div slot-scope="ProviderProps">
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="dataForm.city_id"
                  >
                    <option
                      v-for="(city, index) in cities"
                      :key="city"
                      :value="index"
                    >
                      {{ city }}
                    </option>
                  </select>
                  <p class="invalid-feedback-custom">
                    {{ ProviderProps.errors[0] }}
                  </p>
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-12 mt-5">
              <button
                @click="back()"
                class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
              >
                <i class="fa fa-angle-left mr-2"></i
                ><span class="d-none d-md-inline-block"> Voltar</span>
              </button>
              <button
                :disabled="invalid"
                class="btn btn-primary btn-lg text-uppercase px-4"
              >
                Preencher os detalhes <i class="fa fa-angle-right ml-2"></i>
              </button>
              <span v-b-tooltip.hover title="Necessário preencher campos obrigatórios para avançar." class="ml-2" v-if="invalid">
                <i class="fas fa-exclamation-circle text-warning"></i>
              </span>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </section>
    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center"><b-spinner></b-spinner></div>
  </div>
</template>

<script>
import userTypes from "@/enums/userTypes";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import quotationService from "@/services/quotation";
import commonsService from "@/services/commons";
import { extend } from "vee-validate";
import uiHelperMixin from '@/mixins/uiHelper';
import commomsHelperMixin from '@/mixins/commomsHelper';
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";

extend("fullname", (value) => {
  const nameParts = value.split(" ");
  return nameParts.length > 1 || "Você deve informar seu nome completo";
});

export default {
  name: "InsuredData",
  mixins: [uiHelperMixin, commomsHelperMixin],
  props: {
    quotationId: {
      type: String
    },
    quotationCategory: {
      type: Number
    }
  },
  data: () => ({
    dataForm: { person_type: userTypes.NATURAL, 
                document: null },
    loading: false,
    focus: false,
    states: null,
    insuranceCategoryTypes: insuranceCategoryTypes,
    userTypes: userTypes,
    cities: null,
    isJudicialInsurance: false,
    loadingCnpj: false,
    loadingCep: false,
    insurance_category_id: null,
    isCustomsInsurance: true,
    skipFirstAcess: false,
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    mask,
  },
  mounted() {
    this.loading = true
    this.findQuotationInsured(this.quotationId);
    this.findStates();
    this.scroll();
  },
  computed: {
    sufixStep(){
      return this.isJudicialInsurance && 
        (this.dataForm.insurance_category_id != this.insuranceCategoryTypes.RECEIVE_AN_ADVANCE_PAYMENT) ? 
        'Reclamante' : 'Segurado'
    },
    sufixDocument(){
      return this.allowedResources.indexOf(this.quotationCategory) == 1 ? 'Orgão Aduaneiro Responsavel' : this.isJudicialInsurance && 
        (this.dataForm.insurance_category_id != this.insuranceCategoryTypes.RECEIVE_AN_ADVANCE_PAYMENT) ? 
        'Reclamante' : 'Segurado'
    },
    allowedJudicialFlow() {
      return [this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.ENSURE_A_LABOR_EXECUTION,
        this.insuranceCategoryTypes.I_HAVE_TAX_CREDIT_OR_INSTALLMENT,
        this.insuranceCategoryTypes.TAX_ENFORCEMENT,
        this.insuranceCategoryTypes.APPEAL_A_CIVIL_ACTION,
        this.insuranceCategoryTypes.REPLACEMENT_OF_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.RECEIVE_AN_ADVANCE_PAYMENT,]
    },
    allowedResources() {
      return this.allowedCustoms()
    },
    naturalSelected() {
      return this.dataForm.person_type == userTypes.NATURAL;
    },
    legalSelected() {
      return this.dataForm.person_type == userTypes.LEGAL;
    },
    documentLabel() {
      return this.dataForm.person_type == userTypes.NATURAL
        ? "CPF DO"
        : "CNPJ DO";
    },
    nameLabel() {
      return this.dataForm.person_type == userTypes.NATURAL
        ? "NOME COMPLETO DO"
        : "RAZÃO SOCIAL DO";
    },
    documentMask() {
      return this.dataForm.person_type == userTypes.NATURAL
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    cepMask() {
      return "#####-###";
    },
    rulesDocument() {
      return this.dataForm.person_type == userTypes.NATURAL
        ? 'required|min:14'
        : 'required|min:18'
    },
    documentValue() {
      return this.dataForm.document;
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    changeToNaturalType() {
      this.dataForm.person_type = userTypes.NATURAL;
    },
    changeToLegalType() {
      this.dataForm.person_type = userTypes.LEGAL;
    },
    async findCities(state_id) {
      this.resetAlert();
      await commonsService
        .cities(state_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.cities = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cidades.");
        });
    },
    searchCep: function() {
      this.loadingCep = true;
      commonsService.findCEP(this.dataForm.zipcode).then(response => {
        this.dataForm.zipcode = response.data.zipcode;
        this.dataForm.address = response.data.address;
        this.dataForm.district = response.data.district;
        this.dataForm.state_id = response.data.state_id;
        this.findCities(this.dataForm.state_id);
        this.dataForm.city_id = response.data.city_id;
        this.$refs.address_number.focus();
      }).catch(() => {
        this.showError('Não foi possível encontrar dados desse cep.');
      }).finally(() => {
        this.loadingCep = false;
      });
    },
    quotation() {
      this.resetAlert();
      this.loading = true;
      if (this.dataForm.person_type == this.userTypes.NATURAL) {
        this.dataForm.is_public = false
      }
      quotationService
        .updateInsured(this.quotationId, this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.error);
            return;
          }
          this.$router.push({
            name: "quotation-details",
            params: { quotationId: this.quotationId },
          });
        })
        .catch((error) => {
          this.showError(Object.values(error.response.data.errors));
        }).finally(() => {
          this.loading = false;
        });
    },
    findStates() {
      this.resetAlert();
      commonsService
        .states()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.states = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os estados.");
        });
    },
    findCnpj(cnpj) {
      this.resetAlert();
      this.resetFields();
      this.loadingCnpj = true;
      commonsService
        .findCnpj(this.traitCnpj(cnpj))
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.fillFields(response.data, true);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as informações do cnpj.");
        })
        .finally(() => {
          this.loadingCnpj = false;
        });
    },
    back() {
      this.$router.push({
        name: "garantee-data",
        params: { quotationId: this.quotationId },
      });
    },
    findQuotationInsured(uid) {
      quotationService
        .getInsuredByUID(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            this.loading = false;
            return;
          }
          this.fillFields(response.data);
          this.findQuotation(uid)
        })
        .catch(() => {
          this.showError("Não foi possível recuperar o segurado.");
          this.loading = false;
        });
    },
    findQuotation(uid) {
      quotationService
        .getByUID(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.dataForm.insurance_category_id = response.data.insurance_category_id
          if(response.data.insurance_category_id == this.insuranceCategoryTypes.RECEIVE_AN_ADVANCE_PAYMENT){
            this.dataForm.person_type = this.userTypes.LEGAL
          }
          this.isJudicialInsurance = this.allowedJudicialFlow.indexOf(response.data.insurance_category_id) != -1
          this.isCustomsInsurance = this.allowedResources.indexOf(response.data.insurance_category_id) != -1
          if (this.isCustomsInsurance) this.dataForm.person_type = userTypes.LEGAL
          this.insurance_category_id = response.data.insurance_category_id
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a cotação.");
        }).finally(() => {
          this.isCivilAction();
          this.loading = false;
        });
    },
    fillFields(element, findCnpjRequest = false) {
      if (element != "") {
        this.dataForm.name = element.name;
        this.dataForm.address = element.address;
        this.dataForm.address_number = element.address_number;
        this.dataForm.zipcode = element.zipcode;
        this.dataForm.address_line_2 = element.address_line_2;
        this.dataForm.district = element.district;
        this.dataForm.state_id = element.city
          ? element.city.state.id
          : element.state_id;
        this.findCities(this.dataForm.state_id);
        this.dataForm.city_id = element.city_id;
        this.dataForm.is_public = element.is_public;
        if (!findCnpjRequest) {
          this.skipFirstAcess = true;
          this.dataForm.person_type = element.person_type;
          this.dataForm.document = element.document;
        }
      }
      this.setFocus();
    },
    setFocus() {
      this.focus = true;
      this.focus = false;
    },
    resetFields() {
      this.dataForm = {
        person_type: this.dataForm.person_type,
        document: this.dataForm.document,
      };
      this.setFocus();
    },
    isCivilAction(){
      if(
        this.dataForm.insurance_category_id == this.insuranceCategoryTypes.APPEAL_A_CIVIL_ACTION ||
        this.dataForm.insurance_category_id == this.insuranceCategoryTypes.I_HAVE_TAX_CREDIT_OR_INSTALLMENT
        ){
        this.dataForm.person_type = userTypes.LEGAL;
      }
    },
  },
  watch:{
    documentValue(data) {
      if(data.length == 18){
        this.skipFirstAcess ? this.skipFirstAcess = false : this.findCnpj(data)
      }
    }
  },
};
</script>

<style>
</style>
