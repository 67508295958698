import commonsService from "@/services/commons";

export default {
  methods: {
    searchCep() {
      this.loadingCep = true;
      commonsService
        .findCEP(this.dataForm.zipcode)
        .then(response => {
          this.resetAlert();
          this.dataForm.zipcode = response.data.zipcode;
        })
        .catch(() => {
          this.showError("Não foi possível encontrar dados desse cep.");
        })
        .finally(() => {
          this.loadingCep = false;
        });
    },
    searchFullCep: function() {
      this.loadingCep = true;
      commonsService
        .findCEP(this.dataForm.zipcode)
        .then(response => {
          this.resetAlert();
          this.dataForm.zipcode = response.data.zipcode;
          this.dataForm.address = response.data.address;
          this.dataForm.district = response.data.district;
          this.dataForm.state_id = response.data.state_id;
          this.findCities(this.dataForm.state_id);
          this.dataForm.city_id = response.data.city_id;
          this.dataForm.rental_guarantee_id = this.renterQuotationId;

          this.$refs.number.focus();
        })
        .catch(() => {
          this.showError("Não foi possível encontrar dados desse cep.");
        })
        .finally(() => {
          this.loadingCep = false;
        });
    },
    searchCepSimple() {
      commonsService
        .findCEPSimple(this.dataForm.zipcode)
        .then(response => {
          return response.data
        })
        .catch(() => {
          console.log("Não foi possível encontrar dados desse cep.");
        })
    },
  }
};
