import http from "@/services/http";

const get = () => {
  return http.get("rental-guarantee");
};
const store = dataForm => http.post("rental-guarantee", dataForm);
const update = (uid, dataForm) =>
  http.patch(`rental-guarantee/${uid}`, dataForm);
const getByUID = uid => http.get(`rental-guarantee/${uid}`);

const storeIntended = dataForm =>
  http.post("rental-guarantee/intended-properties", dataForm);
const updateIntended = (uid, dataForm) =>
  http.patch(`rental-guarantee/${uid}/intended-properties`, dataForm);
const getPropertyByUID = uid =>
  http.get(`rental-guarantee/${uid}/intended-properties`);

const storeTerm = dataForm =>
  http.post("rental-guarantee/term-contract", dataForm);
const updateTerm = (uid, dataForm) =>
  http.patch(`rental-guarantee/${uid}/term-contract`, dataForm);
const getTermByUID = uid => http.get(`rental-guarantee/${uid}/term-contract`);

const getRentalFiles = uid =>
  http.get(`rental-guarantee/${uid}/rental-guarantee-files`);
const downloadFile = (uid, fileId) =>
  http.get(`rental-guarantee/${uid}/rental-guarantee-files/${fileId}`, {
    responseType: "blob"
  });
const deleteRentalFile = (uid, name) =>
  http.delete(`rental-guarantee/${uid}/rental-guarantee-files/${name}`);

export default {
  store,
  update,
  get,
  getByUID,
  storeIntended,
  updateIntended,
  getPropertyByUID,
  storeTerm,
  updateTerm,
  getTermByUID,
  getRentalFiles,
  downloadFile,
  deleteRentalFile
};
