<template>
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid  }">
      <b-form @submit.stop.prevent="handleSubmit(quotation)" class="form" autocomplete="off">

      <div class="container container-cozy">
        <div class="row">
          <div class="col-12 text-center">
            <header class="mb-5">
              <h2 class="text-title-step">Seu Seguro Garantia descomplicado com a cotação mais rápida do mercado!</h2>
            </header>
          </div>
          <div v-if="alertMessage" class="col-12">
            <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
          </div>
          <div class="col-12" >
            <div class="row">
              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="documento"
                  rules="required"
                  v-slot="validationContext"
                  vid="document"
                >
                  <label class="dbo-ui">CNPJ DO TOMADOR</label>
                  <b-form-input
                    v-model="dataForm.document"
                    :state="getValidationState(validationContext)"
                    placeholder="CNPJ da sua empresa"
                    v-mask="documentMask"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="nome"
                  rules="required|max:191|fullname"
                  v-slot="validationContext">
                  <label class="dbo-ui">Razão Social</label>
                  <b-form-input
                    :autofocus="focus"
                    v-model="dataForm.name"
                    :state="getValidationState(validationContext)"
                    placeholder="Razão social da sua empresa"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 col-lg-12 mt-5">
                <button :disabled="invalid" class="button btn btn-primary btn-pretty icon-right">Informar dados <i class="fa fa-angle-right ml-2"></i></button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </b-form>
    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center"><b-spinner></b-spinner></div>
    </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import quotationService from "@/services/quotation";
import { extend } from 'vee-validate';
import commonsService from "@/services/commons";
import insuranteTypes from '@/enums/insuranceTypes';
import uiHelperMixin from '@/mixins/uiHelper';
import commomsHelperMixin from '@/mixins/commomsHelper';

extend('fullname', value => {
  const nameParts = value.split(' ');
  return nameParts.length > 1 || 'Você deve informar seu nome completo';
});

export default {
  name: "GeneralData",
  mixins: [uiHelperMixin, commomsHelperMixin],
  props: {
    quotationId: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  directives: {
    mask
  },
  data: () => ({
    dataForm: { },
    loading: false,
    focus: false,
    validate: false
  }),
  computed:{
    document(){
      return this.dataForm.document;
    },
    documentMask() {
      return '##.###.###/####-##';
    }
  },
  created(){
    if(this.quotationId){
      this.getQuotation(this.quotationId)
    }
    this.scroll();
  },
  methods: {
    async getQuotation(uid){
      await quotationService.getByUID(uid).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return;
        }
       this.fillFields(response.data, true)
      }).catch(() => {
        this.showError('Não foi possível recuperar a cotação.');
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    storeQuotation(){
      return quotationService.store(this.dataForm)
    },
    updateQuotation(){
      return quotationService.update(this.quotationId, this.dataForm)
    },
    quotation() {
      this.resetAlert();
      this.loading = true;
      let savePromise = this.quotationId ? this.updateQuotation() : this.storeQuotation();
      savePromise.then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        var uid = response.data.id
        this.$router.push({ name: 'garantee-types-selection', params: { quotationId: uid }});
      }).catch(() => {
        this.showError('Não foi possível executar a cotação.');
      }).finally(() => {
        this.loading = false;
      })
    },
    fillFields(element, firstAccess = false){
      if(firstAccess){
        this.validate = true
        this.$set(this.dataForm, 'document', element.document);
      }else{
        this.validate = false
      }
      this.dataForm.name = element.name
      this.dataForm.insurance_type_id = insuranteTypes.GUARANTEE
      this.setFocus()
    },
    setFocus(){
      this.focus = true
      this.focus = false
    },
    findCnpj(cnpj) {
      this.resetAlert();
      this.resetFields();
      this.loading = true;
      commonsService
        .findCnpj(this.traitCnpj(cnpj))
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.fillFields(response.data)
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as informações do cnpj.");
        }).finally(() =>{
          this.loading = false;
        })
    },
    resetFields() {
      this.dataForm = {
        document: this.dataForm.document,
        insurance_type_id: insuranteTypes.GUARANTEE
      };
      this.setFocus();
    },
  },
  watch:{
    document(value){
      if(!this.validate){
        if(value.length == 18){
          this.findCnpj(value)
        }
        return
      }
      this.validate = false
    }
  }
}
</script>

<style>

</style>
