<template>
  <div class="col-12 col-lg-3 mb-4">
    <component
      :is="comingSoon ? 'a' : 'router-link'"
      :to="comingSoon ? undefined : insuranceType.route"
      class="card-button card h-100"
    >
      <div class="card-body shadow" :style="styleOptions" v-b-tooltip.hover.v-light="tooltipOptions" :title="disabledMessage">
        <span class="fa-stack fa-4x mb-3">
          <i class="fa fa-circle fa-stack-2x"></i>
          <i class="fa fa-stack-1x fa-inverse" :class="insuranceType.class"></i>
        </span>
        <h3>{{ 'Seguro ' + insuranceType.name }}</h3>
        <p class="no-break">
          {{ insuranceType.description }}
        </p>
      </div>
    </component>
  </div>
</template>

<script>
export default {
  name: "InsuranceTypeCard",
  props: {
    insuranceType: {
      type: Object,
      required: true
    },
    comingSoon: {
      type: Boolean,
      default: false
    },
    comingSoonMessage: {
      type: String,
      default: 'Em breve este tipo de seguro estará disponível para você'
    }
  },
  computed: {
    tooltipOptions() {
      return {
        disabled: !this.comingSoon
      };
    },
    styleOptions() {
      return {
        opacity: this.comingSoon ? '0.3' : '1'
      }
    }, 
    disabledMessage() {
      return this.comingSoon ? this.comingSoonMessage : ''
    }
  }
};
</script>

<style>
</style>
