export default {
  GUARANTEE: 1,
  RENTER_GUARANTEE: 2,
  ENGINEERING_RISK: 3,
  CIVIL_RESPONSABILITY: 4,
  AERONAUTICAL: 5,
  AUTO: 6,
  BUSINESS_PROPERTY_INSURANCE: 7,
  RISK_MANAGEMENT: 8,
  RD_EQUIPMENT: 9,
  AGRIBUSINESS: 10,
  SHIPBUILDER: 11,
  CYBERNETIC: 12,
  PORT_OPERATOR: 13,
  NAUTICAL: 14,
  MISCELLANEOUS_RISKS: 15,
  LIFE_INSURANCE: 16,
  TRANSPORT: 17,
  RESIDENTIAL: 18,
  HEALTH: 19,
  DENTAL: 20,
  OTHERS: 21
}
