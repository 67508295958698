<template>
  <div class="container container-cozy">
    <div class="row">
      <div class="col-12 col-lg-12">
        <header class="mb-5">
          <h2 class="text-center">
            Selecione o tipo de garantia que você precisa:
          </h2>
        </header>
      </div>
    </div>
    <div v-if="alertMessage" class="col-12">
      <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>
    <section>
      <div class="row">
        <InsuranceCategoryCard
          :key="insuranceCategory.id"
          v-for="insuranceCategory in filteredInsuranceCategoryTypes"
          :selectedInsuranceCategory="selectedInsuranceCategory"
          :insuranceCategory="insuranceCategory"
          :comingSoon="!insuranceCategory.supported"
          :disabled="insuranceCategory.block"
          @click="setTypeQuotation"/>
      </div>
      <p class="d-flex justify-content-between">
          <router-link class="btn btn-secondary mt-3"
            :to="{ name: 'quotation' }"
          ><i class="fa fa-arrow-left mr-2"></i> 
           <span class="d-none d-md-inline-block"> Voltar</span>
          </router-link>
        </p>
    </section>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import insuranceTypes from "@/enums/insuranceTypes";
import http from "@/services/http";
import InsuranceCategoryCard from '@/components/Quotation/InsuranceCategoryCard';

export default {
  name: "GeneralData",
  components: {
    InsuranceCategoryCard
  },
  props: {
    quotationId: {
      type: String
    }
  },
  data: () => ({
    insuranceCategoryTypes: [],
    dataForm: {
      insurance_category_id: null,
    },
    selectedInsuranceCategory: null
  }),
  created() {
    this.getInsuranceCategories();
    this.dataForm.insurance_type_id = insuranceTypes.GUARANTEE;
  },
  computed: {
    filteredInsuranceCategoryTypes() {
      if (!this.selectedInsuranceCategory) {
        return this.insuranceCategoryTypes;
      }

      return this.selectedInsuranceCategory.sub_categories;
    },
  },
  methods: {
    back() {
      this.$router.push({
        name: "guarantee-quotation",
        params: { quotationId: this.quotationId },
      });
    },
    setTypeQuotation(type) {
      if (!type.sub_categories) {
        this.dataForm.insurance_category_id = type.id;
        this.quotation();
      }
      this.selectedInsuranceCategory = type;
    },
    quotation() {
      this.resetAlert();
      quotationService
        .store(this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.$router.push({
            name: "garantee-data",
            params: { quotationId: response.data.id },
          });
        })
        .catch(() => {
          this.showError("Não foi possível executar a cotação.");
        });
    },
    getInsuranceCategories() {
      http
        .get(`insurance-types/${insuranceTypes.GUARANTEE}/insurance-categories`)
        .then((response) => {
          this.insuranceCategoryTypes = response.data.filter((e) => e.supported);
        });
    },
  },
};
</script>

<style>
</style>
